import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { IQuery } from 'src/types/iquery'
import axios from 'axios'
import { ISftpCredentials, ISftpFileList } from 'src/types/iexport'
import history from 'src/history'
import Cookies from 'js-cookie'
import { ICron, ICronFilter, IImportError } from 'src/types/icron';

interface ICronState {
  crons: ICron[]
  errors: IImportError[]
  errorText: string
}

const initialState: ICronState = {
  crons: [],
  errors: [],
  errorText: ""
}

const slice = createSlice({
  name: 'cron',
  initialState,
  reducers: {
    setErrorText(state: ICronState, action: PayloadAction<string>){
      state.errorText = action.payload
    },
    setCrons(state: ICronState, action: PayloadAction<ICron[]>){
      state.crons = action.payload
    },
    setErrors(state: ICronState, action: PayloadAction<IImportError[]>){
      state.errors = action.payload
    }
  }
})

export const reducer = slice.reducer

export const setErrorText = (value: string):AppThunk => dispatch => {
  dispatch(slice.actions.setErrorText(value))
}

export const getCrons = (mappingId: string, onFinish: () => void):AppThunk => async dispatch => {
  var data = JSON.stringify({mappingId, storeId: Cookies.get("storeIdDataLoader")})
  try{
    const response = await axios.post<ICron[]>(`${process.env.REACT_APP_AUTH_URL}/crons/all`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.setCrons(response.data))
    onFinish()
  }catch(error){
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const getCronErrors = (cronId: string, onFinish: (errors: IImportError[]) => void):AppThunk => async dispatch => {
  var data = JSON.stringify({cronId})
  try{
    const response = await axios.post<IImportError[]>(`${process.env.REACT_APP_AUTH_URL}/crons/errors`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.setErrors(response.data))
    onFinish(response.data)
  }catch(error){
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const getCronErrorsWithQuery = (cronId: string, filter: ICronFilter, onFinish: (errors: IImportError[]) => void):AppThunk => async dispatch => {
  var data = JSON.stringify({cronId, filter})
  try{
    const response = await axios.post<IImportError[]>(`${process.env.REACT_APP_AUTH_URL}/crons/errorsfilter`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.setErrors(response.data))
    onFinish(response.data)
  }catch(error){
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export default slice