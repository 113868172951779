import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';

interface IFAQState{
  tab: string,
  innerTabRedux: string
}

const initialState:IFAQState = {
  tab: '',
  innerTabRedux: ''
}

const slice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    setTab(state: IFAQState, action: PayloadAction<string>){
      state.tab = action.payload
    },
    setInnerTab(state: IFAQState, action: PayloadAction<string>){
      state.innerTabRedux = action.payload
    }
  }
})

export const reducer = slice.reducer

export const setTab = (value: string):AppThunk => dispatch => {
  dispatch(slice.actions.setTab(value))
}

export const setInnerTab = (value: string):AppThunk => dispatch => {
  dispatch(slice.actions.setInnerTab(value))
}

export default slice