import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as mappingsReducer } from 'src/slices/mapping';
import { reducer as rowsStateReducer } from 'src/slices/rowsState';
import { reducer as exportReducer } from 'src/slices/export';
import { reducer as loginReducer } from 'src/slices/login'
import { reducer as importReducer } from 'src/slices/import'
import { reducer as queryReducer } from 'src/slices/query'
import { reducer as faqReducer } from 'src/slices/faq'
import { reducer as transformerReducer } from 'src/slices/transformer'
import { reducer as fulfillmentReducer } from 'src/slices/fulfillment'
import { reducer as channelsReducer } from 'src/slices/channels'
import { reducer as cronReducer } from 'src/slices/cron'

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  mapping: mappingsReducer,
  rowsState: rowsStateReducer,
  export: exportReducer,
  login: loginReducer,
  import: importReducer,
  query: queryReducer,
  faq: faqReducer,
  transformer: transformerReducer,
  fulfillment: fulfillmentReducer,
  channels: channelsReducer,
  cron: cronReducer
});

export default rootReducer;
