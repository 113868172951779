import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFieldMapping, IMetaData } from 'src/types/imapping'
import type { AppThunk } from 'src/store'
import { ITransformer } from 'src/types/itransformers';
import { values } from 'lodash';
import { date } from 'yup';

interface IRowsState {
  rowsState: IFieldMapping[],
  metaDataState?: IMetaData[],
  iterator?: string,
  mappingName: string
  delimiter?: string
  listDelimiter?: string
  exportType?: string
  elementContent?: string
  xmlHeader?: string
}

const initialState: IRowsState = {
  rowsState: [],
  metaDataState: [],
  iterator: 'item',
  mappingName: '',
  delimiter: '',
  listDelimiter: '',
  exportType: '',
  elementContent: 'channel',
  xmlHeader: 'rss'
}

const slice = createSlice({
  name: 'rowsState',
  initialState,
  reducers: {
    setMetaDataState(state: IRowsState, action: PayloadAction<IMetaData[]>) {
      state.metaDataState = action.payload
    },
    setIterator(state: IRowsState, action: PayloadAction<string>) {
      state.iterator = action.payload
    },
    setMappingName(state: IRowsState, action: PayloadAction<string>) {
      state.mappingName = action.payload
    },
    setRowsState(state: IRowsState, action: PayloadAction<IFieldMapping[]>) {
      state.rowsState = action.payload
    },
    onIteratorChange(state: IRowsState, action: PayloadAction<string>) {
      state.iterator = action.payload
    },
    addNewChannelFieldRow(state: IRowsState) {
      state.metaDataState = [...state.metaDataState, { field: '', value: '' }]
    },
    deleteMetaDataRow(state: IRowsState, action: PayloadAction<number>) {
      state.metaDataState = [...state.metaDataState.slice(0, action.payload), ...state.metaDataState.slice(action.payload + 1)]
    },
    onMetaDataFieldChange(state: IRowsState, action: PayloadAction<{ value: string, index: number }>) {
      state.metaDataState = state.metaDataState.map((data, i) => {
        if (i !== action.payload.index) {
          return data
        }
        return {
          ...data, field: `channel.${action.payload.value}`
        }
      })
    },
    onMetaDataValueChange(state: IRowsState, action: PayloadAction<{ value: string, index: number }>) {
      state.metaDataState = state.metaDataState.map((data, i) => {
        if (i !== action.payload.index) {
          return data
        }
        return {
          ...data, value: action.payload.value
        }
      })
    },
    onTargetFieldChange(state: IRowsState, action: PayloadAction<{ value: string, index: number }>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i !== action.payload.index) {
          return data
        }
        return {
          ...data, targetField: action.payload.value
        }
      })
    },
    onTwigTemplateChange(state: IRowsState, action: PayloadAction<{ value: string, index: number }>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i !== action.payload.index) {
          return data
        }
        return {
          ...data, staticValue: action.payload.value
        }
      })
    },
    deleteRowsStateRow(state: IRowsState, action: PayloadAction<number>) {
      state.rowsState = [...state.rowsState.slice(0, action.payload), ...state.rowsState.slice(action.payload + 1)]
    },
    changeFinalValue(state: IRowsState, action: PayloadAction<{ value: string, index: number }>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i !== action.payload.index) {
          return data
        }
        return {
          ...data, finalValue: action.payload.value
        }
      })
    },
    onMappingNameChange(state: IRowsState, action: PayloadAction<string>) {
      state.mappingName = action.payload
    },
    handleNewFieldRow(state: IRowsState) {
      state.rowsState = [...state.rowsState, {
        sourceField: '',
        targetField: '',
        validator: '',
        transformers: [],
        sourceValue: '',
        finalValue: '',
        staticValue: '',
        isStatic: false,
        isTwig: false,
        isImages: false,
        isCheckBox: false,
        imageSize: '',
        imageType: '',
        imageTwig: '',
        isWarning: false,
        isIdentifier: false
      }]
    },
    handleNewTwigRow(state: IRowsState) {
      state.rowsState = [...state.rowsState, {
        sourceField: '',
        targetField: '',
        validator: '',
        transformers: [],
        sourceValue: '',
        finalValue: '',
        staticValue: '',
        isStatic: false,
        isTwig: true,
        isImages: false,
        isCheckBox: false,
        imageSize: '',
        imageType: '',
        imageTwig: '',
        isWarning: false,
        isIdentifier: false
      }]
    },
    handleNewStaticRow(state: IRowsState) {
      state.rowsState = [...state.rowsState, {
        sourceField: '',
        targetField: '',
        validator: '',
        transformers: [],
        sourceValue: '',
        finalValue: '',
        staticValue: '',
        isStatic: true,
        isTwig: false,
        isImages: false,
        isCheckBox: false,
        imageSize: '',
        imageType: '',
        imageTwig: '',
        isWarning: false,
        isIdentifier: false
      }]
    },
    changeStaticField(state: IRowsState, action: PayloadAction<{ value: string, index: number }>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i !== action.payload.index) {
          return data
        }
        return {
          ...data, staticValue: action.payload.value, finalValue: action.payload.value
        }
      })
    },
    changeSourceField(state: IRowsState, action: PayloadAction<{ value: string, index: number }>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i !== action.payload.index) {
          return data
        }
        return {
          ...data, sourceField: action.payload.value
        }
      })
    },
    deleteRowTransformers(state: IRowsState, action: PayloadAction<number>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i !== action.payload) {
          return data
        }
        return {
          ...data, transformers: []
        }
      })
    },
    changeSourceValue(state: IRowsState, action: PayloadAction<{ value: string | number | boolean | any[], index: number }>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i !== action.payload.index) {
          return data
        }
        return {
          ...data, sourceValue: action.payload.value
        }
      })
    },
    changeValidator(state: IRowsState, action: PayloadAction<{ validator: string, index: number }>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i !== action.payload.index) {
          return data
        }
        return {
          ...data, validator: action.payload.validator
        }
      })
    },
    updateRowTransformers(state: IRowsState, action: PayloadAction<{ transformers: ITransformer[], index: number }>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i !== action.payload.index) {
          return data
        }
        return {
          ...data, transformers: action.payload.transformers
        }
      })
    },
    addNewRowTransformer(state: IRowsState, action: PayloadAction<{ transformer: ITransformer, index: number }>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i !== action.payload.index) {
          return data
        }
        return {
          ...data, transformers: [...data.transformers, action.payload.transformer]
        }
      })
    },
    RowsStateBackToInitialState(state: IRowsState) {
      state.iterator = 'item'
      state.mappingName = ''
      state.metaDataState = []
      state.rowsState = []
    },
    setDelimiter(state: IRowsState, action: PayloadAction<string>) {
      state.delimiter = action.payload
    },
    setListDelimiter(state: IRowsState, action: PayloadAction<string>) {
      state.listDelimiter = action.payload
    },
    setExportType(state: IRowsState, action: PayloadAction<string>) {
      state.exportType = action.payload
    },
    handleImageSizeChange(state: IRowsState, action: PayloadAction<{ value: string, index: number }>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i !== action.payload.index) {
          return data
        }
        return {
          ...data, imageSize: action.payload.value
        }
      })
    },
    handleImageTypeChange(state: IRowsState, action: PayloadAction<{ value: string, index: number }>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i !== action.payload.index) {
          return data
        }
        return {
          ...data, imageType: action.payload.value
        }
      })
    },
    setIsImages(state: IRowsState, action: PayloadAction<{ value: boolean, index: number }>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i !== action.payload.index) {
          return data
        }
        return {
          ...data, isImages: action.payload.value
        }
      })
    },
    changeImageTwig(state: IRowsState, action: PayloadAction<{ value: string, index: number }>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i !== action.payload.index) {
          return data
        }
        return {
          ...data, imageTwig: action.payload.value
        }
      })
    },
    changeIsCheckBox(state: IRowsState, action: PayloadAction<{ value: boolean, index: number }>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i !== action.payload.index) {
          return data
        }
        return {
          ...data, isCheckBox: action.payload.value
        }
      })
    },
    changeIsWarning(state: IRowsState, action: PayloadAction<{ value: boolean, index: number }>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i !== action.payload.index) {
          return data
        }
        return {
          ...data, isWarning: action.payload.value
        }
      })
    },
    changeIsIdentifier(state: IRowsState, action: PayloadAction<{ value: boolean, index: number }>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i !== action.payload.index) {
          return data
        }
        return {
          ...data, isIdentifier: action.payload.value
        }
      })
    },
    setIsRequired(state: IRowsState, action: PayloadAction<{ value: boolean, index: number }>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i !== action.payload.index) {
          return data
        }
        return {
          ...data, isRequired: action.payload.value
        }
      })
    },
    setIsLookUp(state: IRowsState, action: PayloadAction<{ value: boolean, index: number }>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i != action.payload.index) {
          return data
        }
        return {
          ...data, isLookUp: action.payload.value
        }
      })
    },
    setHasOptions(state: IRowsState, action: PayloadAction<{ value: boolean, index: number }>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i != action.payload.index) {
          return data
        }
        return {
          ...data, has_options: action.payload.value
        }
      })
    },
    setOptions(state: IRowsState, action: PayloadAction<{ value: string[], index: number }>) {
      state.rowsState = state.rowsState.map((data, i) => {
        if (i != action.payload.index) {
          return data
        }
        return {
          ...data, options: action.payload.value
        }
      })
    },
    setElementContent(state: IRowsState, action: PayloadAction<string>){
      state.elementContent = action.payload
    },
    setXMLHeader(state: IRowsState, action: PayloadAction<string>){
      state.xmlHeader = action.payload
    },
    setNote(state: IRowsState, action: PayloadAction<{ value: string, index: number }>){
      state.rowsState = state.rowsState.map((data, i) => {
        if (i != action.payload.index) {
          return data
        }
        return {
          ...data, note: action.payload.value
        }
      })
    }
  }
})

export const reducer = slice.reducer

export const setMetaDataState = (metaData: IMetaData[]): AppThunk => dispatch => {
  dispatch(slice.actions.setMetaDataState(metaData))
}

export const setIterator = (iterator: string): AppThunk => dispatch => {
  dispatch(slice.actions.setIterator(iterator))
}

export const setMappingName = (mappingName: string): AppThunk => dispatch => {
  dispatch(slice.actions.setMappingName(mappingName))
}

export const setRowsState = (rowsState: IFieldMapping[]): AppThunk => dispatch => {
  dispatch(slice.actions.setRowsState(rowsState))
}

export const onIteratorChange = (value: string): AppThunk => dispatch => {
  dispatch(slice.actions.onIteratorChange(value))
}

export const addNewChannelFieldRow = (): AppThunk => dispatch => {
  dispatch(slice.actions.addNewChannelFieldRow())
}

export const deleteMetaDataRow = (index: number): AppThunk => dispatch => {
  dispatch(slice.actions.deleteMetaDataRow(index))
}

export const onMetaDataFieldChange = (value: string, index: number): AppThunk => dispatch => {
  dispatch(slice.actions.onMetaDataFieldChange({ value, index }))
}

export const onMetaDataValueChange = (value: string, index: number): AppThunk => dispatch => {
  dispatch(slice.actions.onMetaDataValueChange({ value, index }))
}

export const onTargetFieldChange = (value: string, index: number): AppThunk => dispatch => {
  dispatch(slice.actions.onTargetFieldChange({ value, index }))
}

export const onTwigTemplateChange = (value: string, index: number): AppThunk => dispatch => {
  dispatch(slice.actions.onTwigTemplateChange({ value, index }))
}

export const deleteRowsStateRow = (index: number): AppThunk => dispatch => {
  dispatch(slice.actions.deleteRowsStateRow(index))
}

export const changeFinalValue = (value: string, index: number): AppThunk => dispatch => {
  dispatch(slice.actions.changeFinalValue({ value, index }))
}

export const onMappingNameChange = (value: string): AppThunk => dispatch => {
  dispatch(slice.actions.onMappingNameChange(value))
}

export const handleNewFieldRow = (): AppThunk => dispatch => {
  dispatch(slice.actions.handleNewFieldRow())
}

export const handleNewTwigRow = (): AppThunk => dispatch => {
  dispatch(slice.actions.handleNewTwigRow())
}

export const handleNewStaticRow = (): AppThunk => dispatch => {
  dispatch(slice.actions.handleNewStaticRow())
}

export const changeStaticField = (value: string, index: number): AppThunk => dispatch => {
  dispatch(slice.actions.changeStaticField({ value, index }))
}

export const changeSourceField = (value: string, index: number): AppThunk => dispatch => {
  dispatch(slice.actions.changeSourceField({ value, index }))
}

export const deleteRowTransformers = (index: number): AppThunk => dispatch => {
  dispatch(slice.actions.deleteRowTransformers(index))
}

export const changeSourceValue = (value: string | number | boolean | any[], index: number): AppThunk => dispatch => {
  dispatch(slice.actions.changeSourceValue({ value, index }))
}

export const changeValidator = (validator: string, index: number): AppThunk => dispatch => {
  dispatch(slice.actions.changeValidator({ validator, index }))
}

export const updateRowTransformers = (transformers: ITransformer[], index: number): AppThunk => dispatch => {
  dispatch(slice.actions.updateRowTransformers({ transformers, index }))
}

export const addNewRowTransformer = (transformer: ITransformer, index: number): AppThunk => dispatch => {
  dispatch(slice.actions.addNewRowTransformer({ transformer, index }))
}

export const RowsStateBackToInitialState = (): AppThunk => dispatch => {
  dispatch(slice.actions.RowsStateBackToInitialState())
}

export const setDelimiter = (value: string): AppThunk => dispatch => {
  dispatch(slice.actions.setDelimiter(value))
}

export const setListDelimiter = (value: string): AppThunk => dispatch => {
  dispatch(slice.actions.setListDelimiter(value))
}

export const setExportType = (value: string): AppThunk => dispatch => {
  dispatch(slice.actions.setExportType(value))
}

export const handleImageSizeChange = (value: string, index: number): AppThunk => dispatch => {
  dispatch(slice.actions.handleImageSizeChange({ value, index }))
}

export const handleImageTypeChange = (value: string, index: number): AppThunk => dispatch => {
  dispatch(slice.actions.handleImageTypeChange({ value, index }))
}

export const setIsImages = (value: boolean, index: number): AppThunk => dispatch => {
  dispatch(slice.actions.setIsImages({ value, index }))
}

export const changeImageTwig = (value: string, index: number): AppThunk => dispatch => {
  dispatch(slice.actions.changeImageTwig({ value, index }))
}

export const changeIsCheckBox = (value: boolean, index: number): AppThunk => dispatch => {
  dispatch(slice.actions.changeIsCheckBox({ value, index }))
}

export const changeIsWarning = (value: boolean, index: number): AppThunk => dispatch => {
  dispatch(slice.actions.changeIsWarning({ value, index }))
}

export const changeIsIdentifier = (value: boolean, index: number): AppThunk => dispatch => {
  dispatch(slice.actions.changeIsIdentifier({ value, index }))
}

export const setIsRequired = (value: boolean, index: number): AppThunk => dispatch => {
  dispatch(slice.actions.setIsRequired({ value, index }))
}

export const setIsLookUp = (value: boolean, index: number): AppThunk => dispatch => {
  dispatch(slice.actions.setIsLookUp({ value, index }))
}

export const setHasOptions = (value: boolean, index: number): AppThunk => dispatch => {
  dispatch(slice.actions.setHasOptions({ value, index }))
}

export const setOptions = (value: string[], index: number): AppThunk => dispatch => {
  dispatch(slice.actions.setOptions({ value, index }))
}

export const setElementContent = (value: string):AppThunk => dispatch => {
  dispatch(slice.actions.setElementContent(value))
}

export const setXMLHeader = (value: string):AppThunk => dispatch => {
  dispatch(slice.actions.setXMLHeader(value))
}

export const setNote = (value: string, index: number):AppThunk => dispatch => {
  dispatch(slice.actions.setNote({value, index}))
}

export default slice