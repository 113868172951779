import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import history from 'src/history';
import type { AppThunk } from 'src/store';
import { ILoginResult, ISite, IUser } from 'src/types/ilogin';

interface ILoginState {
  storeId: string
  qrCode?: string
  email: string
  password: string
  accessToken?: string
  errorText?: string
  user: IUser
  stores: string[]
  redirectRoute: string
  refreshLogin: boolean
  isRefreshStores: boolean
}

const initialState: ILoginState = {
  storeId: '',
  qrCode: '',
  password: '',
  email: '',
  accessToken: '',
  errorText: '',
  user: null,
  stores: [],
  redirectRoute: '',
  refreshLogin: false,
  isRefreshStores: false
}

const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    handleLogin(state: ILoginState, action: PayloadAction<ILoginResult>) {
      state.password = action.payload.password
      state.email = action.payload.email
    },
    verifyCode(state: ILoginState, action: PayloadAction<string>) {
      state.accessToken = action.payload
    },
    deleteQrCode(state: ILoginState) {
      state.qrCode = ''
    },
    setErrorText(state: ILoginState, action: PayloadAction<string>) {
      state.errorText = action.payload
    },
    signUp(state: ILoginState, action: PayloadAction<IUser>) {
      state.storeId = action.payload.storeId
      state.qrCode = action.payload.qr
      state.email = action.payload.email
    },
    handleSignIn(state: ILoginState, action: PayloadAction<string[]>) {
      state.stores = action.payload
    },
    setEmailRedux(state: ILoginState, action: PayloadAction<string>) {
      state.email = action.payload
    },
    deleteLoginStores(state: ILoginState) {
      state.stores = []
    },
    setRedirectRoute(state: ILoginState, action: PayloadAction<string>) {
      state.redirectRoute = action.payload
    },
    setRefreshLogin(state: ILoginState, action: PayloadAction<boolean>) {
      state.refreshLogin = action.payload
    },
    setSiteErrorText(state: ILoginState, action: PayloadAction<string>) {
      state.errorText = action.payload
    },
    setIsRefreshStores(state: ILoginState, action: PayloadAction<boolean>) {
      state.isRefreshStores = action.payload
    }
  }
})

export const reducer = slice.reducer

export const handleLogin = (email: string, password: string, storeId: string): AppThunk => async dispatch => {
  dispatch(slice.actions.setErrorText(''))
  var data = JSON.stringify({ email, password, storeId })
  try {
    const response = await axios.post<ILoginResult>(`${process.env.REACT_APP_AUTH_URL}/users/login`, data, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.handleLogin(response.data))
    history.push('/verification')
  } catch (error) {
    dispatch(slice.actions.setErrorText('Your E-Mail and password does not match'))
  }
}

export const handleSignIn = (email: string, password: string, onSignInFinish: () => void): AppThunk => async dispatch => {
  dispatch(slice.actions.setErrorText(''))
  var data = JSON.stringify({
    grant_type: "password",
    username: email,
    password
  })
  try {
    if (email == "demo@acenda.com" && password == "Demo1234") {
      dispatch(slice.actions.handleSignIn(["mainstore"]))
      dispatch(slice.actions.handleLogin({ email, password }))
      history.push('/verification')
    } else {
      const response = await axios.post('https://www.acenda.com/oauth/token', data, {
        headers: {
          'Content-Type': 'application/json'
        },
      })
      dispatch(slice.actions.handleSignIn(response.data))
      onSignInFinish()
    }
  } catch (error) {
    if (error.message.includes("403")) {
      dispatch(slice.actions.handleLogin({ email, password }))
      history.push('/verification')
    } else {
      dispatch(slice.actions.setErrorText(error.message))
    }
  }
}

const sleep = (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const verifyCode = (email: string, password: string, passCode: string, onResponseVerification: (accessToken: string) => void): AppThunk => async dispatch => {
  dispatch(slice.actions.setErrorText(''))
  var data = JSON.stringify({ username: email, password, gac: passCode, grant_type: "password" })
  try {
    if (email == "demo@acenda.com") {
      Cookies.set("sites", JSON.stringify(["mainstore"]), { expires: 0.125 })
      Cookies.set("currentSite", "mainstore", { expires: 0.125 })
      Cookies.set('storeIdDataLoader', "mainstore", { expires: 0.125 })
      dispatch(getDataManagerToken("mainstore"))
      await sleep(2000)
      onResponseVerification("testAccount")
    } else {
      const response = await axios.post(`https://www.acenda.com/oauth/token`, data, {
        headers: {
          'Content-Type': 'application/json'
        },
      })
      onResponseVerification(response.data.access_token)
    }
    //dispatch(slice.actions.verifyCode(response.data))
  } catch (error) {
    dispatch(slice.actions.setErrorText('Verification Code Incorrect!'))
  }
}

export const deleteQrCode = (): AppThunk => dispatch => {
  dispatch(slice.actions.deleteQrCode())
}

export const signUp = (email: string, password: string, storeId: string, apiKey: string, onSignUpFinish: () => void): AppThunk => async dispatch => {
  var data = JSON.stringify({ email, password, storeId, apiKey })
  try {
    const response = await axios.post<IUser>(`${process.env.REACT_APP_AUTH_URL}/users/signup`, data, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.signUp(response.data))
    onSignUpFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const setEmailRedux = (value: string): AppThunk => dispatch => {
  dispatch(slice.actions.setEmailRedux(value))
  history.push('/signup')
}

export const deleteLoginStores = (): AppThunk => dispatch => {
  dispatch(slice.actions.deleteLoginStores())
}

export const setRedirectRoute = (route: string): AppThunk => dispatch => {
  dispatch(slice.actions.setRedirectRoute(route))
}

export const setRefreshLogin = (value: boolean): AppThunk => dispatch => {
  dispatch(slice.actions.setRefreshLogin(value))
}

export const setErrorText = (value: string): AppThunk => dispatch => {
  dispatch(slice.actions.setErrorText(value))
}

export const getDataManagerToken = (storeId: string): AppThunk => async dispatch => {
  var data = JSON.stringify({ storeId })
  try {
    const response = await axios.post<string>(`${process.env.REACT_APP_AUTH_URL}/users/verification`, data, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    Cookies.set('accessToken', response.data, { expires: 0.125 })
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const getAcendaSites = (accessToken: string, email: string, isRefresh: boolean, onFinish: () => void): AppThunk => async dispatch => {
  try {

    let page = 1
    const limit = 1000
    let url = `https://acenda.com/api/site?sort=title:1&limit=${limit}&page=${page}`
    let response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    let sites = []
    response.data.result.forEach(site => {
      sites.push(site.name)
    })

    while (response.data.result.length == 1000) {
      page += 1
      url = `https://acenda.com/api/site?sort=title:1&limit=${limit}&page=${page}`
      response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      response.data.result.forEach(site => {
        sites.push(site.name)
      })
    }

    const responseFilter = await axios.post<ISite>(`${process.env.REACT_APP_AUTH_URL}/site/services`, JSON.stringify({ email, isRefresh, sites }), {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    const filteredSites = responseFilter.data
    Cookies.set("sites", JSON.stringify(filteredSites.acendaSites), { expires: 0.125 })
    Cookies.set("currentSite", filteredSites.lastSite, { expires: 0.125 })
    Cookies.set('storeIdDataLoader', filteredSites.lastSite, { expires: 0.125 })
    dispatch(getDataManagerToken(filteredSites.lastSite))
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText("Couldn't get sites!"))
  }
}

export const setSiteErrorText = (value: string): AppThunk => dispatch => {
  dispatch(slice.actions.setSiteErrorText(value))
  history.push('/login')
}

export const changeStore = (lastSite: string, email: string, isChangeRoute: boolean): AppThunk => async dispatch => {
  try {
    dispatch(getDataManagerToken(lastSite))
    var data = JSON.stringify({ lastSite, email })
    const response = await axios.post<string>(`${process.env.REACT_APP_AUTH_URL}/site/lastsite`, data, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    Cookies.set("currentSite", response.data, { expires: 0.125 })
    Cookies.set('storeIdDataLoader', response.data, { expires: 0.125 })
    dispatch(slice.actions.setIsRefreshStores(true))
    if (isChangeRoute) {
      history.push('/app/home')
    }
  } catch (error) {
    throw error
  }
}

export const refreshStores = (email: string): AppThunk => async dispatch => {
  try {
    let page = 1
    const limit = 1000
    let url = `https://acenda.com/api/site?limit=${limit}&page=${page}`
    let response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${Cookies.get('acendaAccessToken')}`
      }
    })
    let sites = []
    response.data.result.forEach(site => {
      sites.push(site.name)
    })

    while (response.data.result.length == 1000) {
      page += 1
      url = `https://acenda.com/api/site?limit=${limit}&page=${page}`
      response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${Cookies.get('acendaAccessToken')}`
        }
      })
      response.data.result.forEach(site => {
        sites.push(site.name)
      })
    }


    const responseFilter = await axios.post<ISite>(`${process.env.REACT_APP_AUTH_URL}/site/services`, JSON.stringify({ email, isRefresh: true, sites }), {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    const filteredSites = responseFilter.data
    Cookies.set("sites", JSON.stringify(filteredSites.acendaSites), { expires: 0.125 })
    Cookies.set("currentSite", filteredSites.lastSite, { expires: 0.125 })
    Cookies.set('storeIdDataLoader', filteredSites.lastSite, { expires: 0.125 })
    dispatch(getDataManagerToken(filteredSites.lastSite))
    await new Promise(resolve => setTimeout(resolve, 1000));
    dispatch(slice.actions.setIsRefreshStores(true))
    history.push('/app/home')
  } catch (error) {
    dispatch(slice.actions.setErrorText("Couldn't get sites!"))
  }
}

export default slice