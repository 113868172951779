import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import GuestGuard from 'src/components/GuestGuard';
import AuthGuard from './components/AuthGuard';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/shopify/callback',
    guard: AuthGuard,
    component: lazy(() => import ('src/views/shopify/ShopifyCallback'))
  },
  {
    exact: true,
    path: '/app/faq',
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import('src/views/faq'))
  },
  {
    exact: true,
    path: '/shopify',
    component: lazy(() => import ('src/views/shopify/ShopifyCallback'))
  },
  {
    exact: true,
    path: '/faq',
    component: lazy(() => import('src/views/faq'))
  },
  {
    exact: true,
    path: '/privacy',
    component: lazy(() => import('src/views/privacy'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/Login'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/signup',
    component: lazy(() => import('src/views/Login/signup'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/verification',
    component: lazy(() => import('src/views/Login/verification'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    layout: DashboardLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/app/home',
        component: lazy(() => import('src/views/home'))
      },
      {
        exact: true,
        path: '/app/channels',
        component: lazy(() => import('src/views/channels'))
      },
      { exact: true,
        path: '/app/channels/:channelpath/:title',
        component: lazy(() => import('src/views/channels/channel'))
      },
      {
        exact: true,
        path: '/app/channels/:channelpath/:id/:title/edit',
        component: lazy(() => import('src/views/channels/edit'))
      },
      {
        exact: true,
        path: '/app/channels/:channelpath/:id/rules',
        component: lazy(() => import('src/views/channels/rules'))
      },
      {
        exact: true,
        path: '/app/channels/:channelpath/:id/rules/edit/:ruleid',
        component: lazy(() => import('src/views/channels/rules/edit'))
      },
      {
        exact: true,
        path: '/app/channels/:channelpath/:id/rules/create',
        component: lazy(() => import('src/views/channels/rules/create'))
      },
      {
        exact: true,
        path: '/app/transformers',
        component: lazy(() => import('src/views/transformers/TransformerMain'))
      },
      {
        exact: true,
        path: '/app/transformers/create',
        component: lazy(() => import('src/views/transformers/TransformerCreate'))
      },
      {
        exact: true,
        path: '/app/transformers/edit/:id',
        component: lazy(() => import('src/views/transformers/TransformerEdit'))
      },
      {
        exact: true,
        path: '/app/:baseroute/crons/:id',
        component: lazy(() => import('src/components/Cron'))
      },
      {
        exact: true,
        path: '/app/query',
        component: lazy(() => import('src/views/query/QueryMain'))
      },
      {
        exact: true,
        path: '/app/query/edit/:id',
        component: lazy(() => import('src/views/query/QueryEdit'))
      },
      {
        exact: true,
        path: '/app/query/create',
        component: lazy(() => import('src/views/query/QueryCreate'))
      },
      {
        exact: true,
        path: '/app/:baseRoute/create/manual',
        component: lazy(() => import('src/components/Upload/UploadManual'))
      },
      {
        exact: true,
        path: '/app/:baseRoute/import/manual/:id',
        component: lazy(() => import('src/components/Upload/UploadManual'))
      },
      {
        exact: true,
        path: '/app/:baseRoute/create/sftp',
        component: lazy(() => import('src/components/Upload/UploadSFTP'))
      },
      {
        exact: true,
        path: '/app/:baseRoute/import/sftp/:id',
        component: lazy(() => import('src/components/Upload/UploadSFTP'))
      },
      {
        exact: true,
        path: '/app/acenda',
        component: lazy(() => import('src/views/acenda/AcendaMain'))
      },
      {
        exact: true,
        path: '/app/acenda/create',
        component: lazy(() => import('src/views/acenda/AcendaCreate'))
      },
      {
        exact: true,
        path: '/app/acenda/fulfillment',
        component: lazy(() => import('src/views/acenda/AcendaFulfillmentRules'))
      },
      {
        exact: true,
        path: '/app/acenda/fulfillment/create',
        component: lazy(() => import('src/views/acenda/AcendaFulfillmentRules/createFulfillment'))
      },
      {
        exact: true,
        path: '/app/acenda/fulfillment/edit/:index',
        component: lazy(() => import('src/views/acenda/AcendaFulfillmentRules/editFulfillment'))
      },
      {
        exact: true,
        path: '/app/acenda/edit/:id',
        component: lazy(() => import('src/views/acenda/AcendaEdit'))
      },
      {
        exact: true,
        path: '/app/acenda/compliance/:id',
        component: lazy(() => import('src/views/acenda/AcendaCompliance'))
      },
      {
        exact: true,
        path: '/app/acenda/category/:id',
        component: lazy(() => import('src/views/acenda/AcendaCategory'))
      },
      {
        exact: true,
        path: '/app/acenda/import/:id',
        component: lazy(() => import('src/views/acenda/AcendaImport'))
      },
      {
        exact: true,
        path: '/app/bigcommerce',
        component: lazy(() => import('src/views/bigcommerce/BigCommerceMain'))
      },
      {
        exact: true,
        path: '/app/bigcommerce/create',
        component: lazy(() => import('src/views/bigcommerce/BigCommerceCreate'))
      },
      {
        exact: true,
        path: '/app/bigcommerce/edit/:id',
        component: lazy(() => import('src/views/bigcommerce/BigCommerceEdit'))
      },
      {
        exact: true,
        path: '/app/magento',
        component: lazy(() => import('src/views/magento/MagentoMain'))
      },
      {
        exact: true,
        path: '/app/magento/create',
        component: lazy(() => import('src/views/magento/MagentoCreate'))
      },
      {
        exact: true,
        path: '/app/magento/edit/:id',
        component: lazy(() => import('src/views/magento/MagentoEdit'))
      },
      {
        exact: true,
        path: '/app/magento/import/:id',
        component: lazy(() => import('src/views/magento/MagentoMain'))
      },
      {
        exact: true,
        path: '/app/csvimport',
        component: lazy(() => import('src/views/csvimport/CsvImportMain'))
      },
      {
        exact: true,
        path: '/app/csvimport/create',
        component: lazy(() => import('src/views/csvimport/CsvImportCreate'))
      },
     /* {
        exact: true,
        path: '/app/csvimport/import/:id',
        component: lazy(() => import('src/views/csvimport/CsvImportImport'))
      },*/
      {
        exact: true,
        path: '/app/csvimport/edit/:id',
        component: lazy(() => import('src/views/csvimport/CsvImportEdit'))
      },
      {
        exact: true,
        path: '/app/googlefeed',
        component: lazy(() => import('src/views/googlemerchantcenter/GoogleMerchantCenterMain'))
      },
      {
        exact: true,
        path: '/app/googlefeed/create',
        component: lazy(() => import('src/views/googlemerchantcenter/GoogleMerchantCenterCreate'))
      },
     /* {
        exact: true,
        path: '/app/googlefeed/import/:id',
        component: lazy(() => import('src/views/csvimport/CsvImportImport'))
      },*/
      {
        exact: true,
        path: '/app/googlefeed/edit/:id',
        component: lazy(() => import('src/views/googlemerchantcenter/GoogleMerchantCenterEdit'))
      },
      {
        exact: true,
        path: '/app/shopify',
        component: lazy(() => import('src/views/shopify/ShopifyMain'))
      },
      {
        exact: true,
        path: '/app/shopify/create',
        component: lazy(() => import('src/views/shopify/ShopifyCreate'))
      },
      {
        exact: true,
        path: '/app/shopify/create/shop',
        component: lazy(() => import('src/views/shopify/ShopifyCreate/shop'))
      },
      {
        exact: true,
        path: '/app/shopify/edit/:id',
        component: lazy(() => import('src/views/shopify/ShopifyEdit'))
      },
      {
        exact: true,
        path: '/app/shopify/import/:id',
        component: lazy(() => import('src/views/shopify/ShopifyImport'))
      },
      {
        exact: true,
        path: '/app/channeladvisor',
        component: lazy(() => import('src/views/channeladvisor/ChannelAdvisorMain'))
      },
      {
        exact: true,
        path: '/app/channeladvisor/create',
        component: lazy(() => import('src/views/channeladvisor/ChannelAdvisorCreate'))
      },
      {
        exact: true,
        path: '/app/channeladvisor/edit/:id',
        component: lazy(() => import('src/views/channeladvisor/ChannelAdvisorEdit'))
      },
      {
        exact: true,
        path: '/app/channeladvisor/import/:id',
        component: lazy(() => import('src/views/channeladvisor/ChannelAdvisorImport'))
      },
      {
        exact: true,
        path: '/app/salsify',
        component: lazy(() => import('src/views/salsify/SalsifyMain'))
      },
      {
        exact: true,
        path: '/app/salsify/create',
        component: lazy(() => import('src/views/salsify/SalsifyCreate'))
      },
      {
        exact: true,
        path: '/app/salsify/edit/:id',
        component: lazy(() => import('src/views/salsify/SalsifyEdit'))
      },
      {
        exact: true,
        path: '/app/salsify/import/:id',
        component: lazy(() => import('src/views/salsify/SalsifyImport'))
      },
      {
        exact: true,
        path: '/app/amazon',
        component: lazy(() => import('src/views/amazon/AmazonMain'))
      },
      {
        exact: true,
        path: '/app/amazon/create',
        component: lazy(() => import('src/views/amazon/AmazonCreate'))
      },
      {
        exact: true,
        path: '/app/amazon/edit/:id',
        component: lazy(() => import('src/views/amazon/AmazonEdit'))
      },
      {
        exact: true,
        path: '/app/amazon/import/:id',
        component: lazy(() => import('src/views/amazon/AmazonImport'))
      },
      {
        exact: true,
        path: '/app/export-xml',
        component: lazy(() => import('src/views/export-xml/ExportXMLMain'))
      },
      {
        exact: true,
        path: '/app/export-xml/create',
        component: lazy(() => import('src/views/export-xml/ExportXMLCreate'))
      },
      {
        exact: true,
        path: '/app/export-xml/edit/:id',
        component: lazy(() => import('src/views/export-xml/ExportXMLEdit'))
      },
      {
        exact: true,
        path: '/app/export-xml/export/:id',
        component: lazy(() => import('src/views/export-xml/ExportXMLExport'))
      },
      {
        exact: true,
        path: '/app/export-csv',
        component: lazy(() => import('src/views/export-csv/ExportCSVMain'))
      },
      {
        exact: true,
        path: '/app/export-csv/create',
        component: lazy(() => import('src/views/export-csv/ExportCSVCreate'))
      },
      {
        exact: true,
        path: '/app/export-csv/edit/:id',
        component: lazy(() => import('src/views/export-csv/ExportCSVEdit'))
      },
      {
        exact: true,
        path: '/app/export-csv/export/:id',
        component: lazy(() => import('src/views/export-csv/ExportCSVExport'))
      },
      {
        exact: true,
        path: '/app/shopifyexport',
        component: lazy(() => import('src/views/shopifyexport/ShopifyExportMain'))
      },
      {
        exact: true,
        path: '/app/shopifyexport/create',
        component: lazy(() => import('src/views/shopifyexport/ShopifyExportCreate'))
      },
      {
        exact: true,
        path: '/app/shopifyexport/edit/:id',
        component: lazy(() => import('src/views/shopifyexport/ShopifyExportEdit'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/home" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  
  {
    path: '*',
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/login" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
