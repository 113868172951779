import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { useDispatch } from 'src/store'
import { setRedirectRoute } from 'src/slices/login'

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated, isInitialised } = useAuth();
  const dispatch = useDispatch()

  if (!isAuthenticated) {
    console.log(window.location.pathname + window.location.search)
    dispatch(setRedirectRoute(window.location.pathname + window.location.search))
    return <Redirect to="/login" />;
  }

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
