import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useSnackbar } from 'notistack'
import { deleteLoginStores, setRefreshLogin } from 'src/slices/login'
import { useDispatch } from 'src/store'
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Button
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import { THEMES } from 'src/constants';
import type { Theme } from 'src/theme';
import useAuth from 'src/hooks/useAuth';
import Cookies from 'js-cookie'

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },
  toolbar: {
    minHeight: 64
  }
}));

const TopBar: FC<TopBarProps> = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const {enqueueSnackbar}  = useSnackbar()
  const { logout } = useAuth();
  const dispatch = useDispatch()

  const handleLogout = () => {
    try {
      Cookies.remove('accessToken')
      Cookies.remove('acendaAccessToken')
      Cookies.remove('sites')
      Cookies.remove('currentSite')
      dispatch(setRefreshLogin(true))
      dispatch(deleteLoginStores())
      logout();
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <img alt="Acenda" src="https://admin.acenda.com/images/acenda-logo-tm-white.png" style={{ marginLeft:"2%" , height: "20px", width: "auto"}}>
          </img>
        </Hidden>
        <Box
          ml={2}
          flexGrow={1}
        />
        <Box ml={2}>
          <Button
            variant="contained"
            onClick={ handleLogout }
            color="secondary"
            style={{ width: '120px' }}
            startIcon={<ExitToAppIcon />}
          >
            Log Out
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
