import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { IQuery } from 'src/types/iquery'
import axios from 'axios'
import { ISftpCredentials, ISftpFileList } from 'src/types/iexport'
import history from 'src/history'
import Cookies from 'js-cookie'

interface IExportState {
  queryList: IQuery[]
  extension: string
  fileList: ISftpFileList[]
  errorText: string
}

const initialState: IExportState = {
  queryList: [],
  extension: '',
  fileList: [],
  errorText: ''
}

const slice = createSlice({
  name: 'export',
  initialState,
  reducers: {
    getQueryList(state: IExportState, action: PayloadAction<IQuery[]>) {
      state.queryList = action.payload
    },
    handleExport() {
    },
    setExtension(state: IExportState, action: PayloadAction<string>) {
      state.extension = action.payload
    },
    getSFTPFiles(state: IExportState, action: PayloadAction<ISftpFileList[]>) {
      state.fileList = action.payload
    },
    deleteFileList(state: IExportState) {
      state.fileList = []
    },
    setExportStateToInitial(state: IExportState) {
      state.queryList = []
      state.extension = ''
      state.fileList = []
    },
    setErrorText(state: IExportState, action: PayloadAction<string>) {
      state.errorText = action.payload
    }

  }
})

export const reducer = slice.reducer

export const getQueryList = (onFinishGetQueryList: () => void): AppThunk => async dispatch => {
  try {
    const response = await axios.post<IQuery[]>(`${process.env.REACT_APP_AUTH_URL}/query/list`, {}, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`
      }
    })
    dispatch(slice.actions.getQueryList(response.data))
    onFinishGetQueryList()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const handleExportXML = (id: string, query: string, queryType: string, email: string, sftpCredentials: ISftpCredentials): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ '_id': id, query, queryType, email, sftpCredentials })
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/export-xml`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.handleExport())
    history.push('/app/export-xml')
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const setExtension = (value: string): AppThunk => dispatch => {
  dispatch(slice.actions.setExtension(value))
}

export const getSFTPFiles = (host: string, port: string, username: string, password: string, filePath: string): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ host, port, username, password, filePath })
    const response = await axios.post<ISftpFileList[]>(`${process.env.REACT_APP_AUTH_URL}/sftp/get-all-names`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.getSFTPFiles(response.data))
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const deleteFileList = (): AppThunk => dispatch => {
  dispatch(slice.actions.deleteFileList())
}

export const setExportStateToInitial = (path: string): AppThunk => dispatch => {
  dispatch(slice.actions.setExportStateToInitial())
  history.push(path)
}

export const handleExportCSV = (id: string, query: string, email: string, sftpCredentials: ISftpCredentials): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ '_id': id, query, email, sftpCredentials })
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/mappings/write-csv`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.handleExport())
    history.push('/app/export-csv')
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}
export const setErrorText = (value: string): AppThunk => dispatch => {
  dispatch(slice.actions.setErrorText(value))
}

export default slice