import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { ITransformer } from 'src/types/itransformers';
import axios from 'axios';
import Cookies from 'js-cookie'
import history from 'src/history'

interface ITransformerState {
  transformerList: ITransformer[],
  errorTextTransformer: string,
  currentTransformer: ITransformer
}
const initialState:ITransformerState = {
  transformerList: [],
  errorTextTransformer: '',
  currentTransformer: null
}

const slice = createSlice({
  name: 'transformer',
  initialState,
  reducers: {
    setErrorText(state: ITransformerState, action: PayloadAction<string>){
      state.errorTextTransformer = action.payload
    },
    getTransformerList(state: ITransformerState, action: PayloadAction<ITransformer[]>){
      state.transformerList = action.payload
    },
    createTransformer(state: ITransformerState, action: PayloadAction<ITransformer>){
      state.transformerList = [...state.transformerList, action.payload]
    },
    deleteTransformer(state: ITransformerState, action: PayloadAction<string>){
      state.transformerList = state.transformerList.filter( transformer => transformer._id !== action.payload)
    },
    getTransformerById(state: ITransformerState, action: PayloadAction<ITransformer>){
      state.currentTransformer = action.payload
    },
    updateTransformer(state: ITransformerState, action: PayloadAction<ITransformer>){
      state.transformerList = state.transformerList.map((transformer) => {
        if(transformer._id !== action.payload._id){
          return transformer
        }
        return action.payload
      })
    }
  }
})

export const reducer = slice.reducer

export const setErrorTextTransformer = (value: string):AppThunk => dispatch => {
  dispatch(slice.actions.setErrorText(value))
}

export const getTransformerList = (onFinish?: () => void):AppThunk => async dispatch => {
  try{
    const response = await axios.post<ITransformer[]>(`${process.env.REACT_APP_AUTH_URL}/transformers/list`, {}, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`
      },
    })
    dispatch(slice.actions.getTransformerList(response.data))
    if(onFinish){
      onFinish()
    }
  }catch(error){
    dispatch(setErrorTextTransformer(error.response.data.message))
  }
}

export const createTransformer = (transformer: ITransformer):AppThunk => async dispatch => {
  try{
    const response = await axios.post<ITransformer>(`${process.env.REACT_APP_AUTH_URL}/transformers/create`, transformer, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`
      },
    })
    dispatch(slice.actions.createTransformer(response.data))
    history.push("/app/transformers")
  }catch(error){
    dispatch(setErrorTextTransformer(error.response.data.message))
  }
}

export const deleteTransformer = (_id : string):AppThunk => async dispatch => {
  var data = JSON.stringify({ _id })
  try{
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/transformers/delete`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.deleteTransformer(_id))
  }catch(error){
    dispatch(setErrorTextTransformer(error.response.data.message))
  }
}

export const getTransformerById = (_id: string, onFinish: () => void):AppThunk => async dispatch => {
  var data = JSON.stringify({_id})
  try{
    const response = await axios.post<ITransformer>(`${process.env.REACT_APP_AUTH_URL}/transformers/getById`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.getTransformerById(response.data))
    onFinish()
  }catch(error){
    dispatch(setErrorTextTransformer(error.response.data.message))
  }
}

export const updateTransformer = (transformer: ITransformer):AppThunk => async dispatch => {
  try{
    const response = await axios.post<ITransformer>(`${process.env.REACT_APP_AUTH_URL}/transformers/edit`, transformer, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.updateTransformer(response.data))
    history.push("/app/transformers")
  }catch(error){
    dispatch(setErrorTextTransformer(error.response.data.message))
  }
}

export default slice