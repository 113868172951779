import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { Adapter, FeedSource, FeedType } from 'src/types/enums';
import axios from 'axios';
import { ISftpCredentials, ISftpFileList } from 'src/types/iexport'
import Cookies from 'js-cookie'
import { IFeed, IFeedArgs } from 'src/types/ifeed'
import { IQuery } from 'src/types/iquery'
import history from 'src/history'
import { ICategoryMappingImport } from 'src/types/iacendaimport';

interface IImportState {
  errorTextImport: string
  fileList: ISftpFileList[]
  importPreview: object,
  queryList: IQuery[]
  categoryMapping: ICategoryMappingImport
}

const initialState: IImportState = {
  errorTextImport: '',
  fileList: [],
  importPreview: {},
  queryList: [],
  categoryMapping: null
}

const slice = createSlice({
  name: 'import',
  initialState,
  reducers: {
    createFeed() {
    },
    setErrorTextImport(state: IImportState, action: PayloadAction<string>) {
      state.errorTextImport = action.payload
    },
    getSFTPFiles(state: IImportState, action: PayloadAction<ISftpFileList[]>) {
      state.fileList = action.payload
    },
    startAnalyse() {
    },
    getImportPreview(state: IImportState, action: PayloadAction<object>) {
      state.importPreview = action.payload
    },
    deleteImportPreview(state: IImportState) {
      state.importPreview = {}
    },
    checkSubscription() { },
    getQueryList(state: IImportState, action: PayloadAction<IQuery[]>) {
      state.queryList = action.payload
    },
    getCategoriesWithQuery(state: IImportState, action: PayloadAction<ICategoryMappingImport>) {
      state.categoryMapping = action.payload
    },
    deletecategoryMapping(state: IImportState) {
      state.categoryMapping = null
    },
    deleteFileList(state: IImportState){
      state.fileList = []
    }
  }

})

export const reducer = slice.reducer

export const getQueryList = (): AppThunk => async dispatch => {
  try {
    const response = await axios.post<IQuery[]>(`${process.env.REACT_APP_AUTH_URL}/query/list`, {}, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`
      }
    })
    dispatch(slice.actions.getQueryList(response.data))
  } catch (error) {
    dispatch(slice.actions.setErrorTextImport(error.response.data.message))
  }
}

export const createFeed = (
  fileName: string,
  source: FeedSource,
  feedType: FeedType,
  query: string,
  adapter: Adapter,
  onCreateFeedFinish: (args: IFeedArgs) => void
): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ fileName, source, feedType, query, adapter })
    const response = await axios.post<IFeed>(`${process.env.REACT_APP_AUTH_URL}/feeds/create`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.createFeed())
    onCreateFeedFinish({ feedId: response.data._id, adapter: response.data.adapter })
  } catch (error) {
    dispatch(slice.actions.setErrorTextImport(error.response.data.message))
  }
}

export const setErrorTextImport = (value: string): AppThunk => dispatch => {
  dispatch(slice.actions.setErrorTextImport(value))
}

export const getSFTPFiles = (host: string, port: string, username: string, password: string, filePath: string, onFinih: () => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ host, port, username, password, filePath })
    const response = await axios.post<ISftpFileList[]>(`${process.env.REACT_APP_AUTH_URL}/sftp/get-all-names`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.getSFTPFiles(response.data))
    onFinih()
  } catch (error) {
    dispatch(slice.actions.setErrorTextImport(error.response.data.message))
  }
}

export const createFeedForSftp = (
  source: FeedSource,
  feedType: FeedType,
  sftpCredentials: ISftpCredentials,
  adapter: Adapter,
  onCreateFeedFinish: (args: IFeedArgs) => void
): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ source, feedType, sftpCredentials, adapter })
    const response = await axios.post<IFeed>(`${process.env.REACT_APP_AUTH_URL}/feeds/create`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.createFeed())
    onCreateFeedFinish({ feedId: response.data._id, adapter: response.data.adapter })
  } catch (error) {
    dispatch(slice.actions.setErrorTextImport(error.response.data.message))
  }
}

export const startAnalyse = (mappingId: string, adapter: Adapter, feedId: string, path: string, onFileValidationError: (fileError: string) => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ mappingId, adapter, feedId })
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/import-requests/analysis`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.startAnalyse())
    history.push(path)
  } catch (error) {
    let message: string = error.response.data.message
    if (message.startsWith('{')) {
      onFileValidationError(error.response.data.message)
    } else {
      dispatch(slice.actions.setErrorTextImport(message))
    }
  }
}

export const createFeedForImport = (
  fileName: string,
  source: FeedSource,
  feedType: FeedType,
  adapter: Adapter,
  onCreateFeedFinish: (args: IFeedArgs) => void
): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ fileName, source, feedType, adapter })
    const response = await axios.post<IFeed>(`${process.env.REACT_APP_AUTH_URL}/feeds/create`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.createFeed())
    onCreateFeedFinish({ feedId: response.data._id, adapter: response.data.adapter })
  } catch (error) {
    dispatch(slice.actions.setErrorTextImport(error.response.data.message))
  }
}

export const getImportPreview = (mappingId: string, rowId: string, sku: string, onImportPreviewFinish: () => void): AppThunk => async dispatch => {
  var data = JSON.stringify({ mappingId, rowId, sku })
  try {
    const response = await axios.post<object>(`${process.env.REACT_APP_AUTH_URL}/import-requests/preview-by-mapping`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.getImportPreview(response.data))
    onImportPreviewFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorTextImport(error.response.data.message))
  }
}

export const deleteImportPreview = (): AppThunk => dispatch => {
  dispatch(slice.actions.deleteImportPreview())
}

export const startImport = (mappingId: string, query?: string): AppThunk => async dispatch => {
  if (query) {
    var data = JSON.stringify({ mappingId, query })
  } else {
    var data = JSON.stringify({ mappingId })
  }
  try {
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/import-requests/start-import`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
  } catch (error) {
    dispatch(slice.actions.setErrorTextImport(error.response.data.message))
  }
}

export const checkSubscription = (
  vendor: string, 
  apiKey: string, 
  vendorType: string, 
  isCreateOrders: boolean,
  isSync: boolean,
  isDefaultWarehouse: boolean,
  countries: string, 
  onCheckSubscriptionFinish: (vendor: string) => void
  ): AppThunk => async dispatch => {
  var data = JSON.stringify({ vendor, apiKey, vendorType, isCreateOrders, isSync, isDefaultWarehouse, countries })
  try {
    const response = await axios.post(`${process.env.REACT_APP_AUTH_URL}/subscriptions/credentials`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.checkSubscription())
    onCheckSubscriptionFinish(response.data["vendor"])
  } catch (error) {
    dispatch(slice.actions.setErrorTextImport(error.response.data.message))
  }
}

export const getCategoriesWithQuery = (query: string, apiSource: string, onFinish: () => void): AppThunk => async dispatch => {
  var data = JSON.stringify({ query, apiSource })
  try {
    const response = await axios.post<ICategoryMappingImport>(`${process.env.REACT_APP_AUTH_URL}/acenda/get-categories-with-query`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.getCategoriesWithQuery(response.data))
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorTextImport(error.response.data.message))
  }
}

export const deletecategoryMapping = (): AppThunk => dispatch => {
  dispatch(slice.actions.deletecategoryMapping())
}

export const deleteFileList = ():AppThunk => dispatch => {
  dispatch(slice.actions.deleteFileList())
}

export default slice