import React, { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie'
import dataloaderLogo from 'src/assets/css/dataloaderlogo.png'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  Grid,
  IconButton,
  MenuItem,
  Menu,
  CircularProgress
} from '@material-ui/core';
import {
  HelpCircle as HelpCircleIcon,
  Aperture as ApertureIcon,
  Grid as GridIcon,
  Crosshair as CrosshairIcon,
  FolderPlus as FolderPlusIcon,
  Crop as CropIcon,
  FolderMinus as FolderMinusIcon
} from 'react-feather';
import NavItem from './NavItem';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useDispatch } from 'src/store';
import { changeStore, refreshStores } from 'src/slices/login';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const sections: Section[] = [
  {
    subheader: 'Home',
    items: [
      {
        title: 'Home',
        href: '/app/home',
        icon: GridIcon
      },
      {
        title: 'FAQ',
        href: '/app/faq',
        icon: HelpCircleIcon
      },
      {
        title: "Services",
        href: '/app/channels',
        icon: ApertureIcon
      }
    ]
  },
  {
    subheader: 'Imports',
    items: [
      {
        title: 'Acenda',
        href: '/app/acenda',
        icon: FolderPlusIcon
      },
      {
        title: 'Amazon',
        href: '/app/amazon',
        icon: FolderPlusIcon
      },
      {
        title: 'BigCommerce',
        href: '/app/bigcommerce',
        icon: FolderPlusIcon
      },
      {
        title: 'Channel Advisor',
        href: '/app/channeladvisor',
        icon: FolderPlusIcon
      },
      {
        title: 'CSV Import',
        href: '/app/csvimport',
        icon: FolderPlusIcon
      },
      {
        title: 'Google Merchant Center',
        href: '/app/googlefeed',
        icon: FolderPlusIcon
      },
      {
        title: 'Magento',
        href: '/app/magento',
        icon: FolderPlusIcon
      },
      {
        title: 'Salsify',
        href: '/app/salsify',
        icon: FolderPlusIcon
      },
      {
        title: 'Shopify',
        href: '/app/shopify',
        icon: FolderPlusIcon
      }
    ]
  },
  {
    subheader: 'Exports',
    items: [
      {
        title: 'Export XML',
        href: '/app/export-xml',
        icon: FolderMinusIcon
      },
      {
        title: 'Export CSV',
        href: '/app/export-csv',
        icon: FolderMinusIcon
      },
      {
        title: 'Shopify',
        href: '/app/shopifyexport',
        icon: FolderMinusIcon
      }
    ]
  },
  {
    subheader: 'Utils',
    items: [
      {
        title: 'Query',
        icon: CrosshairIcon,
        href: '/app/query'
      },
      {
        title: 'Transformers',
        icon: CropIcon,
        href: '/app/transformers'
      }
    ]
  },

];

function renderNavItems({
  items,
  pathname,
  depth = 0
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch()
  const storeId = Cookies.get("storeIdDataLoader")
  let sites = JSON.parse(Cookies.get("sites"))
  sites = sites.filter(site => site != storeId)
  const [isRefreshingStores, setIsRefreshingStores] = useState<boolean>(false)
  const email = Cookies.get("userEmail")

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const handleStoreChange = (value: string) => {
    setIsRefreshingStores(true)
    dispatch(changeStore(value, email, true))
    handleClose()
  }

  const handleRefreshStores = () => {
    setIsRefreshingStores(true)
    dispatch(refreshStores(email))
  }

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <img
              alt="Data Manager"
              src={dataloaderLogo}
              style={{ width: "50%", height: "auto" }}
            >
            </img>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {email}
            </Typography>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Grid item>
                <Typography
                  variant="body2"
                  color="textSecondary"
                >
                  {storeId}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  disabled={isRefreshingStores}
                  aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              </Grid>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {sites.map(site => {
                  return (
                    <MenuItem key={site} onClick={() => handleStoreChange(site)}><Typography
                      variant="body2"
                      color="textSecondary"
                    >
                      {site}
                    </Typography></MenuItem>
                  )
                })}
              </Menu>
              {isRefreshingStores ?
                <CircularProgress
                  style={{
                    color: '#d4d4d4',
                    width: '20px',
                    height: '20px',
                  }}
                />
                :
                <Grid item>
                <IconButton
                  aria-label="import"
                  onClick={handleRefreshStores}
                >
                  <RefreshIcon />
                </IconButton>
              </Grid>
              }
            </Grid>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
