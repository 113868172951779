import React from 'react';
import type { FC } from 'react';
import { Router } from 'react-router-dom';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  jssPreset,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import { createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';
import history from './history';
import { THEMES } from 'src/constants';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const App: FC = () => {

  const theme = createTheme({
    direction: 'ltr',
    responsiveFontSizes: true,
    theme: THEMES.LIGHT
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider
            dense
            maxSnack={3}
          >
            <Router history={history}>
              <AuthProvider>
                <GlobalStyles />
                <ScrollReset />
                <GoogleAnalytics />
                {renderRoutes(routes)}
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
