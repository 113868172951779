export enum FeedSource {
  "SFTP" = "SFTP",
  "MANUAL" = "MANUAL"
}

export enum Adapter {
  "AMAZON" = "AMAZON",
  "SHOPIFY" = "SHOPIFY",
  "SALSIFY" = "SALSIFY",
  "CHANNELADVISOR" = "CHANNELADVISOR",
  "ACENDA" = "ACENDA",
  "GOOGLEMERCHANTCENTER" = "GOOGLEMERCHANTCENTER",
  "LOWES" = "LOWES",
  "XML" = "XML",
  "CSV" = "CSV",
  "CSVIMPORT" = "CSVIMPORT",
  "BIGCOMMERCE" = "BIGCOMMERCE",
  "GOOGLEFEED" = "GOOGLEFEED",
  "MAGENTO" = "MAGENTO",
  "SHOPIFYEXPORT" = "SHOPIFYEXPORT"
}

export enum DataType {
  "product" = "product",
  "variant" = "variant",
  "order" = "order",
  "orderItem" = "orderItem",
  "category" = "category"
}

export enum Channel {
  MAGENTO = "MAGENTO",
  BIGCOMMERCE = "BIGCOMMERCE",
  NETSUITE = "NETSUITE",
  ACENDA = "ACENDA",
  CIN7 = "CIN7",
  WAYFAIR = "WAYFAIR",
  SPSCOMMERCE = "SPSCOMMERCE",
  SHIPHERO = "SHIPHERO",
  SHOPIFY = "SHOPIFY",
  SKUVAULT = "SKUVAULT",
  ACN = "ACN ",
  AKENEO = "AKENEO"
}

export enum RuleAction {
  DO_NOT_CONTINUE = "DO_NOT_CONTINUE",
  EMAIL = "EMAIL",
}

export enum MappingType {
  INVENTORY = "INVENTORY",
  ORDER = "ORDER",
  SHIPPING_METHODS = "SHIPPING_METHODS",
  FULFILLMENT = "FULFILLMENT",
  PRODUCT = "PRODUCT",
  CANCELLATION = "CANCELLATION"
}

export enum Validator {
  "string" = "string",
  "number" = "number",
  "boolean" = "boolean",
  "array" = "array",
  "object" = "object"
}

export enum FulfillmentType {
  VENDOR_PRIORITY = "VENDOR_PRIORITY",
  SHIPPING_MAPPING = "SHIPPING_MAPPING",
  COUNTRIES = "COUNTRIES"
}

export enum TransformationType {
  FIND_AND_REPLACE = "FIND_AND_REPLACE",
  REGEX = "REGEX",
  TWIG = "TWIG",
  PRICE = "PRICE",
  REMOVE_HTML = "REMOVE_HTML",
  LODASH = "LODASH",
  MATH = "MATH",
  BOOL = "BOOL",
  MULTIPLY = "MULTIPLY",
  ADD = "ADD"
}

export enum ImportRequestStatus {
  "QUEUED" = "QUEUED",
  "IN_PROCESS" = "IN_PROCESS",
  "COMPLETED" = "COMPLETED",
  "CANCELED" = "CANCELED",
  "FAILED" = "FAILED",
  "READY" = "READY"
}

export enum FeedType {
  "AMAZON_CATEGORY_LISTING" = "AMAZON_CATEGORY_LISTING",
  "SALSIFY" = "SALSIFY",
  "SHOPIFY_XLSX" = "SHOPIFY_XLSX",
  "SHOPIFY_CSV" = "SHOPIFY_CSV",
  "CHANNEADVISOR_XLSX" = "CHANNEADVISOR_XLSX",
  "CUSTOM_CSV" = "CUSTOM_CSV",
  "GOOGLEFEED" = "GOOGLEFEED"
}

export enum ImportType {
  "API" = "API",
  "FEED" = "FEED"
}

export enum ImportFrequency {
  "WEEKLY" = "WEEKLY",
  "DAILY" = "DAILY",
  "TWICE_DAILY" = "TWICE_DAILY",
  "THREE_TIMES_DAILY" = "THREE_TIMES_DAILY",
  "FOUR_TIMES_DAILY" = "FOUR_TIMES_DAILY",
  "SIX_TIMES_DAILY" = "SIX_TIMES_DAILY",
  "TWELVE_TIMES_DAILY" = "TWELVE_TIMES_DAILY"
}