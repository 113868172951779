import { IConfiguration, ICsvVariantOption, IImageOption, IMetaFieldMapping } from './../types/imapping';
import { CustomerEmail } from './../types/customer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMapping, IFieldMapping, IMetaData, IAcendaAdapter, ICommissionMapping, IGoogleApi } from 'src/types/imapping'
import { IAcendaPreview } from 'src/types/iacenda'
import type { AppThunk } from 'src/store'
import axios from 'axios';
import { Adapter } from 'src/types/enums'
import history from 'src/history'
import Cookies from 'js-cookie'
import { IComplianceRule } from 'src/types/icompliance';
import { ICategoryFieldsToSendServer } from 'src/types/iacendaimport';
import { BigCommerceCustomFieldMapping } from 'src/types/bigcommerce';
import { ISftpCredentials } from 'src/types/iexport';
import { IFeed } from 'src/types/ifeed';

interface IMappingState {
  mappings: IMapping[],
  currentMapping: IMapping,
  acendaPreview: IAcendaPreview,
  errorText: string,
  previewRow: object,
  acendaAdapters: IAcendaAdapter[]
  callback: object,
}

const initialState: IMappingState = {
  mappings: [],
  currentMapping: null,
  acendaPreview: null,
  errorText: '',
  previewRow: null,
  acendaAdapters: [],
  callback: null
}

const slice = createSlice({
  name: 'mapping',
  initialState,
  reducers: {
    getMappingList(state: IMappingState, action: PayloadAction<IMapping[]>) {
      state.mappings = action.payload
    },
    deleteMapping(state: IMappingState, action: PayloadAction<{ id: string }>) {
      state.mappings = state.mappings.filter((mapping) => mapping._id !== action.payload.id)
    },
    createMapping(state: IMappingState, action: PayloadAction<IMapping>) {
      state.mappings = [...state.mappings, action.payload]
    },
    deleteCurrentMapping(state: IMappingState) {
      state.currentMapping = null
    },
    deleteAcendaPreview(state: IMappingState) {
      state.acendaPreview = null
    },
    getMappingById(state: IMappingState, action: PayloadAction<IMapping>) {
      state.currentMapping = action.payload
    },
    getAcendaPreview(state: IMappingState, action: PayloadAction<IAcendaPreview>) {
      state.acendaPreview = action.payload
    },
    saveMappingXML() {
    },
    saveImportMapping() {

    },
    mappingBackToInitialState(state: IMappingState) {
      state.mappings = []
      state.currentMapping = null
      state.acendaPreview = null
      state.previewRow = null
      state.errorText = ''
    },
    deleteMappingList(state: IMappingState) {
      state.mappings = []
    },
    saveMappingCSV() {
    },
    getImportMappingList(state: IMappingState, action: PayloadAction<IMapping[]>) {
      state.mappings = action.payload
    },
    setErrorText(state: IMappingState, action: PayloadAction<string>) {
      state.errorText = action.payload
    },
    setPreviewRow(state: IMappingState, action: PayloadAction<object>) {
      state.previewRow = action.payload
    },
    getAcendaAdapters(state: IMappingState, action: PayloadAction<IAcendaAdapter[]>) {
      state.acendaAdapters = action.payload
    },
    deleteAcendaAdapter(state: IMappingState, action: PayloadAction<string>) {
      state.acendaAdapters = state.acendaAdapters.filter(acendaAdapter => acendaAdapter.vendor !== action.payload)
    },
    saveCompliance() { },
    saveCommissionMapping() { },
    shopifyCallback(state: IMappingState, action: PayloadAction<object>) {
      state.callback = action.payload
    },
    addCustomFields(state: IMappingState, action: PayloadAction<BigCommerceCustomFieldMapping[]>) {
      state.currentMapping.bigCustomFields = action.payload
    },
    setCurrentMapping(state: IMappingState, action: PayloadAction<IMapping>) {
      state.currentMapping = action.payload
    }
  }
})

export const reducer = slice.reducer

export const getMappings = (adapter: Adapter, isExport: boolean, onFinishGetMappings: () => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ adapter, isExport });
    const response = await axios.post<IMapping[]>(`${process.env.REACT_APP_AUTH_URL}/mappings/list`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    });
    dispatch(slice.actions.getMappingList(response.data))
    onFinishGetMappings()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const deleteMapping = (id: string): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ _id: id })
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/mappings/delete`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.deleteMapping({ id }))
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }

}

export const createAcendaMapping = (adapter: Adapter, apiSource: string, vendorType: string): AppThunk => async dispatch => {
  var data = JSON.stringify({ adapter, apiSource, vendorType })
  try {
    const response = await axios.post<IMapping>(`${process.env.REACT_APP_AUTH_URL}/mappings/create`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.createMapping(response.data))
    history.push(`/app/acenda/edit/${response.data._id}`)
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const getAcendaAdapters = (onGetAcendaAdaptersFinish: () => void): AppThunk => async dispatch => {
  try {
    const response = await axios.post<IAcendaAdapter[]>(`${process.env.REACT_APP_AUTH_URL}/acenda/adapters`, {}, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`
      }
    })
    dispatch(slice.actions.getAcendaAdapters(response.data))
    onGetAcendaAdaptersFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const deleteAcendaAdapter = (vendor: string): AppThunk => async dispatch => {
  var data = JSON.stringify({ vendor })
  try {
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/mappings/delete-acenda-credential`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.deleteAcendaAdapter(vendor))
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const createMapping = (adapter: Adapter, isExport: boolean, credentials: any = null, shop: string = null): AppThunk => async dispatch => {
  try {
    var data = {
      adapter,
      isExport
    }
    if (credentials) {
      data["credentials"] = credentials
    }
    if (shop) {
      data["apiSource"] = shop
    }
    const response = await axios.post<IMapping>(`${process.env.REACT_APP_AUTH_URL}/mappings/create`, JSON.stringify(data), {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.createMapping(response.data))
    switch (response.data.adapter) {
      case Adapter.XML:
        history.push(`/app/export-xml/edit/${response.data._id}`)
        break
      case Adapter.CSV:
        history.push(`/app/export-csv/edit/${response.data._id}`)
        break
      case Adapter.BIGCOMMERCE:
        history.push(`/app/bigcommerce/edit/${response.data._id}`)
        break
      case Adapter.MAGENTO:
        history.push(`/app/magento/edit/${response.data._id}`)
        break
      case Adapter.SHOPIFYEXPORT:
        history.push(`/app/shopifyexport/edit/${response.data._id}`)
        break
    }
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const deleteCurrentMapping = (): AppThunk => dispatch => {
  dispatch(slice.actions.deleteCurrentMapping())
}

export const deleteAcendaPreview = (): AppThunk => dispatch => {
  dispatch(slice.actions.deleteAcendaPreview())
}

export const getMappingById = (id: string): AppThunk => async dispatch => {
  try {
    const response = await axios.get<IMapping>(`${process.env.REACT_APP_AUTH_URL}/mappings/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`
      }
    })
    dispatch(slice.actions.getMappingById(response.data))
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const getAcendaPreview = (sourceAdapter: Adapter, productId: string, productSku: string, apiSource: string, mappingId: string): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({
      sourceAdapter,
      previewProductId: productId,
      previewSku: productSku,
      source: apiSource,
      mappingId
    })
    const response = await axios.post<IAcendaPreview>(`${process.env.REACT_APP_AUTH_URL}/mappings/get-preview-product`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.getAcendaPreview(response.data))
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const saveMappingXML = (
  id: string,
  isExport: boolean,
  adapter: Adapter,
  fieldMappings: IFieldMapping[],
  name: string,
  metaData: IMetaData[],
  elementContent: string,
  xmlHeader: string
): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({
      mapping: {
        _id: id,
        isExport,
        adapter,
        fieldMappings,
        name,
        metaData,
        elementContent,
        xmlHeader
      }
    })
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/mappings/update`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.saveMappingXML())
    history.push('/app/export-xml')
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const saveImportMapping = (
  _id: string,
  name: string,
  fieldMappings: IFieldMapping[],
  isOnlyNewProducts: boolean,
  path: string,
  isBulletFields?: boolean,
  productCreateOrUpdate?: string,
  isOnlyUpdateProducts?: boolean
): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ mapping: { _id, name, fieldMappings, isOnlyNewProducts, isInitMapping: false, isBulletFields, productCreateOrUpdate, isOnlyUpdateProducts } })
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/mappings/update`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.saveImportMapping())
    history.push(path)
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const mappingBackToInitialState = (path: string): AppThunk => dispatch => {
  dispatch(slice.actions.mappingBackToInitialState())
  history.push(path)
}

export const mappingBackToInitialStateNoRoute = (): AppThunk => dispatch => {
  dispatch(slice.actions.mappingBackToInitialState())
}

export const deleteMappingList = (): AppThunk => dispatch => {
  dispatch(slice.actions.deleteMappingList())
}

export const saveMappingCSV = (
  id: string,
  delimiter: string,
  listDelimiter: string,
  exportType: string,
  fieldMappings: IFieldMapping[],
  name: string,
): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({
      mapping: {
        _id: id,
        delimiter,
        listDelimiter,
        exportType,
        fieldMappings,
        name,
      }
    })
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/mappings/update`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.saveMappingCSV())
    history.push('/app/export-csv')
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const getImportMappingList = (adapter: Adapter, onFinishGetMappings: () => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ adapter })
    const response = await axios.post<IMapping[]>(`${process.env.REACT_APP_AUTH_URL}/mappings/list`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.getImportMappingList(response.data))
    onFinishGetMappings()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const createImportMapping = (adapter: Adapter, storeId: string, feedId: string, isExport: boolean, onFileValidationError: (fileError: string) => void): AppThunk => async dispatch => {
  var data = JSON.stringify({ adapter, storeId, feedId, isExport })
  try {
    const response = await axios.post<IMapping>(`${process.env.REACT_APP_AUTH_URL}/mappings/create`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.createMapping(response.data))
    switch (response.data.adapter) {
      case Adapter.AMAZON:
        return history.push(`/app/amazon/edit/${response.data._id}`)
      case Adapter.SALSIFY:
        return history.push(`/app/salsify/edit/${response.data._id}`)
      case Adapter.CHANNELADVISOR:
        return history.push(`/app/channeladvisor/edit/${response.data._id}`)
      case Adapter.SHOPIFY:
        return history.push(`/app/shopify/edit/${response.data._id}`)
    }
  } catch (error) {
    let message: string = error.response.data.message
    if (message.startsWith('{')) {
      onFileValidationError(error.response.data.message)
    } else {
      dispatch(slice.actions.setErrorText(message))
    }
  }
}

export const createSftpMapping = (adapter: Adapter, feedId: string, isSftp: boolean, onFileValidationError: (fileError: string) => void): AppThunk => async dispatch => {
  var data = JSON.stringify({ adapter, feedId, isSftp })
  try {
    const response = await axios.post<IMapping>(`${process.env.REACT_APP_AUTH_URL}/mappings/create`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.createMapping(response.data))
    switch (response.data.adapter) {
      case Adapter.AMAZON:
        history.push(`/app/amazon/edit/${response.data._id}`)
        break
      case Adapter.CSVIMPORT:
        history.push(`/app/csvimport/edit/${response.data._id}`)
        break
      case Adapter.GOOGLEFEED:
        history.push(`/app/googlefeed/edit/${response.data._id}`)
    }
  } catch (error) {
    let message: string = error.response.data.message
    if (message.startsWith('{')) {
      onFileValidationError(error.response.data.message)
    } else {
      dispatch(slice.actions.setErrorText(message))
    }
  }

}

export const setErrorText = (value: string): AppThunk => dispatch => {
  dispatch(slice.actions.setErrorText(value))
}

export const setPreviewRow = (previewRow: object): AppThunk => dispatch => {
  dispatch(slice.actions.setPreviewRow(previewRow))
}

export const saveCompliance = (_id: string, complianceRules: IComplianceRule[], complianceStatus: string): AppThunk => async dispatch => {
  var data = JSON.stringify({ mapping: { _id, complianceRules, complianceStatus } })
  try {
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/mappings/update`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.saveCompliance())
    history.push('/app/acenda')
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}


export const saveCategoryMapping = (
  _id: string,
  categoryMapping: ICategoryFieldsToSendServer[],
  newCategories: ICategoryFieldsToSendServer[],
  onFinish: () => void
)
  : AppThunk => async dispatch => {
    var data = JSON.stringify({ _id, categoryMapping, newCategories })
    try {
      const response = await axios.post<IMapping>(`${process.env.REACT_APP_AUTH_URL}/mappings/category-mapping`, data, {
        headers: {
          Authorization: `Bearer ${Cookies.get('accessToken')}`,
          'Content-Type': 'application/json'
        },
      })
      dispatch(slice.actions.getMappingById(response.data))
      onFinish()
    } catch (error) {
      dispatch(slice.actions.setErrorText(error.response.data.message))
    }
  }

export const saveCommissionMapping = (_id: string, commissionMapping: ICommissionMapping[], onFinish: () => void): AppThunk => async dispatch => {
  var data = JSON.stringify({ _id, commissionMapping })
  try {
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/mappings/commission-mapping-update`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.saveCommissionMapping())
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const shopifyCallback = (adapter: Adapter, code: string, shop: string): AppThunk => async dispatch => {
  var data = JSON.stringify({ adapter, code, shop })
  try {
    const response = await axios.post<object>(`${process.env.REACT_APP_AUTH_URL}/shopify/callback`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    })
    dispatch(slice.actions.shopifyCallback(response.data))
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const createShopifyCallbackMapping = (adapter: object): AppThunk => async dispatch => {
  var data = JSON.stringify({ "adapter": Adapter.SHOPIFY, "apiSource": adapter["shop"] })
  try {
    const response = await axios.post<IMapping>(`${process.env.REACT_APP_AUTH_URL}/mappings/create`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    history.push(`/app/shopify/edit/${response.data._id}`)
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const shopifyCredentialsCallback = (shop: string): AppThunk => async dispatch => {
  var data = JSON.stringify({ shop, isEnabled: true })
  try {
    const response = await axios.post<string>(`${process.env.REACT_APP_AUTH_URL}/shopify/credentials`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    window.location.replace(response.data)
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const refreshShopifyTags = (mappingId: string, tagDelimiters: string[]): AppThunk => async dispatch => {
  var data = JSON.stringify({ mappingId, tagDelimiters })
  try {
    const response = await axios.post<IMapping>(`${process.env.REACT_APP_AUTH_URL}/mappings/refresh-shopify-tags`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.getMappingById(response.data))
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const updateShopifyTagsMapping = (mappingId: string, tagsMapping: IFieldMapping[], tagDelimiters: string[], onFinish: () => void): AppThunk => async dispatch => {
  var data = JSON.stringify({ mappingId, tagsMapping, tagDelimiters })
  try {
    const response = await axios.post<IMapping>(`${process.env.REACT_APP_AUTH_URL}/mappings/update-shopify-tags-mapping`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.getMappingById(response.data))
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const validateBigCommerceCredentials = (apiPath: string, accessToken: string, onFinish: (validation: boolean) => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ apiPath, accessToken })
    const response = await axios.post<boolean>(`${process.env.REACT_APP_AUTH_URL}/bigcommerce/validate`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    onFinish(response.data)
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const getBigCommerceProductIds = (apiPath: string, accessToken: string, onFinish: (ids: string[]) => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ apiPath, accessToken })
    const response = await axios.post<string[]>(`${process.env.REACT_APP_AUTH_URL}/bigcommerce/all-products`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    onFinish(response.data)
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const saveCustomFieldsOnMapping = (customFields: BigCommerceCustomFieldMapping[]): AppThunk => dispatch => {
  dispatch(slice.actions.addCustomFields(customFields))
}

export const saveMappingBigCommerce = (
  id: string,
  bigCustomFields: BigCommerceCustomFieldMapping[],
  fieldMappings: IFieldMapping[],
  name: string,
  isOnlyNewProducts: boolean
): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({
      mapping: {
        _id: id,
        bigCustomFields,
        fieldMappings,
        name,
        isOnlyNewProducts
      }
    })
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/mappings/update`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    history.push('/app/bigcommerce')
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const saveBigCommerceCustomFields = (customFields: BigCommerceCustomFieldMapping[], onFinish: () => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ customFields })
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/bigcommerce/save-custom`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const saveConfigurations = (id: string, configurations: IConfiguration[], onFinish: () => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({
      mapping: {
        _id: id,
        configurations
      }
    })
    const response = await axios.post<IMapping>(`${process.env.REACT_APP_AUTH_URL}/mappings/update`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.setCurrentMapping(response.data))
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const saveVariantOptions = (id: string, variantOptions: string[], onFinish: () => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({
      mapping: {
        _id: id,
        variantOptions
      }
    })
    const response = await axios.post<IMapping>(`${process.env.REACT_APP_AUTH_URL}/mappings/update`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.setCurrentMapping(response.data))
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const saveCsvVariantOptions = (id: string, csvVariantOptions: ICsvVariantOption[], onFinish: () => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({
      mapping: {
        _id: id,
        csvVariantOptions
      }
    })
    const response = await axios.post<IMapping>(`${process.env.REACT_APP_AUTH_URL}/mappings/update`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.setCurrentMapping(response.data))
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const validateMagentoCredentials = (host: string, storeCode: string, accessToken: string, onFinish: (validation: boolean) => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ host, storeCode, accessToken })
    const response = await axios.post<boolean>(`${process.env.REACT_APP_AUTH_URL}/magento/validate`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    onFinish(response.data)
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const getSchedule = (mappingId: string, onFinish: (schedule: IGoogleApi) => void): AppThunk => async dispatch => {
  try {
    const response = await axios.get<IGoogleApi>(`${process.env.REACT_APP_AUTH_URL}/googleapi/${mappingId}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`
      }
    })
    onFinish(response.data)
  } catch (error) {
    onFinish(null)
    // dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const createSchedule = (mappingId: string, description: string, cron: string, uri: string, onFinish: (schedule: IGoogleApi) => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ mappingId, description, cron, uri })
    const response = await axios.post<IGoogleApi>(`${process.env.REACT_APP_AUTH_URL}/googleapi/create`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    onFinish(response.data)
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}
export const importProduct = (mappingId: string, path: string, onFinish: (validation: string) => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ mappingId: mappingId })
    const response = await axios.post(`${process.env.REACT_APP_AUTH_URL}/import-requests/start-import`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    onFinish(path)
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const pauseSchedule = (mappingId: string, onFinish: (schedule: IGoogleApi) => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ mappingId })
    const response = await axios.post<IGoogleApi>(`${process.env.REACT_APP_AUTH_URL}/googleapi/pause`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    onFinish(response.data)
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const resumeSchedule = (mappingId: string, onFinish: (schedule: IGoogleApi) => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ mappingId })
    const response = await axios.post<IGoogleApi>(`${process.env.REACT_APP_AUTH_URL}/googleapi/resume`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    onFinish(response.data)
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const runSchedule = (mappingId: string, onFinish: () => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ mappingId })
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/googleapi/run`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const updateSchedule = (mappingId: string, cron: string, onFinish: (schedule: IGoogleApi) => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ mappingId, cron })
    const response = await axios.post<IGoogleApi>(`${process.env.REACT_APP_AUTH_URL}/googleapi/update`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    onFinish(response.data)
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const saveImageOptions = (mappingId: string, imageOption: IImageOption): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({
      mapping: {
        _id: mappingId,
        imageOption
      }
    })
    await axios.post<IMapping>(`${process.env.REACT_APP_AUTH_URL}/mappings/update`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const getFeedById = (feedId: string, onFinish: (credentials: ISftpCredentials) => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ _id: feedId })
    const response = await axios.post<IFeed>(`${process.env.REACT_APP_AUTH_URL}/feeds/get-feed`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    onFinish(response.data.sftpCredentials)
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const updateFeedCredentials = (feedId: string, credentials: ISftpCredentials, onFinish: () => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ _id: feedId, update: { sftpCredentials: credentials } })
    await axios.post<IFeed>(`${process.env.REACT_APP_AUTH_URL}/feeds/update`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const getPreviewFromFile = (mappingId: string, sourceField: string, sourceValue: string): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ mappingId, sourceField, sourceValue })
    const response = await axios.post<object>(`${process.env.REACT_APP_AUTH_URL}/mappings/previewfromfile`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.setPreviewRow(response.data))
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const getShopifyShopList = (onFinish: (shops: string[]) => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ storeIds: JSON.parse(Cookies.get("sites")) })
    const response = await axios.post<string[]>(`${process.env.REACT_APP_AUTH_URL}/shopify/list`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    onFinish(response.data)
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const createShopifyAdapter = (shop: string): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ shop })
    await axios.post<string[]>(`${process.env.REACT_APP_AUTH_URL}/shopify/createadapter`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(createShopifyCallbackMapping({ shop }))
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const saveShopifyExportMapping = (_id: string, name: string, fieldMappings: IFieldMapping[], query: string, isCheckExistingProducts: boolean, assignVariantImagesToProduct: boolean, isExportCollections: boolean): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({
      mapping: {
        _id,
        fieldMappings,
        name,
        query,
        isCheckExistingProducts,
        assignVariantImagesToProduct,
        isExportCollections
      }
    })
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/mappings/update`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    history.push('/app/shopifyexport')
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const validateShopifyExportCredentials = (
  shopifyStoreName: string,
  apiKey: string,
  password: string,
  sharedSecret: string,
  onFinish: (value: boolean) => void
): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ shopifyStoreName, apiKey, password, sharedSecret })
    const response = await axios.post<boolean>(`${process.env.REACT_APP_AUTH_URL}/mappings/shopifyexport-credentials`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    onFinish(response.data)
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const saveShopifyExportMetaFieldsMapping = (_id: string, metaFieldsMapping: IMetaFieldMapping[], onFinish: () => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({
      mapping: {
        _id,
        metaFieldsMapping
      }
    })
    const response = await axios.post<IMapping>(`${process.env.REACT_APP_AUTH_URL}/mappings/update`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.setCurrentMapping(response.data))
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const saveCollectionConfigurations = (_id: string, sortOrder: string, templateSuffix: string, onFinish: () => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({
      mapping: {
        _id,
        collectionConfigurations:{
          sortOrder,
          templateSuffix
        }
      }
    })
    const response = await axios.post<IMapping>(`${process.env.REACT_APP_AUTH_URL}/mappings/update`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.setCurrentMapping(response.data))
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export default slice