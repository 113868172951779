import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import axios from 'axios';
import Cookies from 'js-cookie'
import { IChannelFieldMappings, IChannelMapping, IChannelProfile, IChannelRules, IServiceSubscription, IShippingMethod } from 'src/types/channels';
import { Channel } from 'src/types/enums';
import history from 'src/history'

interface IChannelsState {
  listOfMappings: IChannelMapping[],
  subscriptions: IServiceSubscription[]
  inventoryDirection: string
  orderDirection: string
  shippingDirection: string
  cancellationDirection: string
  errorText: string,
  channelProfileId: string
  currentChannel: Channel
  currentMapping: IChannelMapping
  mappingPreview: object
  rules: IChannelRules[]
  currentRule: IChannelRules
  getRules: boolean
  serviceTitle: string
  shippingMethods: IShippingMethod[]
  notificationsEmailRedux: string
  actions: any[]
  errors: any[]
}

const initialState: IChannelsState = {
  listOfMappings: [],
  subscriptions: [],
  inventoryDirection: '',
  orderDirection: '',
  shippingDirection: '',
  cancellationDirection: '',
  errorText: '',
  channelProfileId: '',
  currentMapping: null,
  currentChannel: null,
  mappingPreview: null,
  rules: [],
  currentRule: null,
  getRules: true,
  serviceTitle: '',
  shippingMethods: [],
  notificationsEmailRedux: '',
  actions: [],
  errors: []
}

const slice = createSlice({
  name: "channels",
  initialState,
  reducers: {
    setErrorText(state: IChannelsState, action: PayloadAction<string>) {
      state.errorText = action.payload
    },
    getChannelMappings(state: IChannelsState, action: PayloadAction<IChannelProfile>) {
      state.channelProfileId = action.payload._id
      state.listOfMappings = action.payload.listOfMappings
      state.inventoryDirection = action.payload.inventoryDirection
      state.orderDirection = action.payload.orderDirection
      state.shippingDirection = action.payload.shippingDirection
      state.cancellationDirection = action.payload.cancellationDirection
      state.notificationsEmailRedux = action.payload.notificationsEmail
    },
    getSubscribedChannels(state: IChannelsState, action: PayloadAction<IServiceSubscription[]>) {
      state.subscriptions = action.payload
    },
    deleteChannelMappings(state: IChannelsState) {
      state.listOfMappings = []
    },
    setCurrentMapping(state: IChannelsState, action: PayloadAction<IChannelMapping>) {
      state.currentMapping = action.payload
    },
    setCurrentChannel(state: IChannelsState, action: PayloadAction<Channel>) {
      state.currentChannel = action.payload
    },
    channelsBackToInitialState(state: IChannelsState) {
      state.currentMapping = null
      state.listOfMappings = []
      state.subscriptions = []
      state.inventoryDirection = ''
      state.orderDirection = ''
      state.shippingDirection = ''
      state.cancellationDirection = ''
      state.errorText = ''
      state.mappingPreview = null
    },
    getMappingPreview(state: IChannelsState, action: PayloadAction<object>) {
      state.mappingPreview = action.payload
    },
    saveMapping() { },
    setChannelRules(state: IChannelsState, action: PayloadAction<IChannelRules[]>) {
      state.rules = action.payload
    },
    setCurrentRule(state: IChannelsState, action: PayloadAction<IChannelRules>) {
      state.currentRule = action.payload
    },
    addRule(state: IChannelsState, action: PayloadAction<IChannelRules>) {
      state.rules = [...state.rules, action.payload]
    },
    setGetRules(state: IChannelsState, action: PayloadAction<boolean>) {
      state.getRules = action.payload
    },
    deleteRule(state: IChannelsState, action: PayloadAction<number>) {
      state.rules = state.rules.filter((rule, index) => index !== action.payload)
    },
    setServiceTitle(state: IChannelsState, action: PayloadAction<string>) {
      state.serviceTitle = action.payload
    },
    setShippingMethods(state: IChannelsState, action: PayloadAction<IShippingMethod[]>) {
      state.shippingMethods = action.payload
    },
    updateRule(state: IChannelsState, action: PayloadAction<{ rule: IChannelRules, ruleIndex: number }>) {
      state.rules = state.rules.map((stateRule, index) => {
        if (index !== action.payload.ruleIndex) {
          return stateRule
        }
        return {
          ...stateRule, name: action.payload.rule.name, condition: action.payload.rule.condition
        }
      })
    },
    deleteShippingMethods(state: IChannelsState) {
      state.shippingMethods = []
      state.currentMapping = null
    },
    setNotificationsEmail(state: IChannelsState, action: PayloadAction<string>) {
      state.notificationsEmailRedux = action.payload
    },
    setActions(state: IChannelsState, action: PayloadAction<any[]>){
      state.actions = action.payload
    },
    setErrors(state: IChannelsState, action: PayloadAction<any[]>){
      state.errors = action.payload
    },
    cleanErrors(state: IChannelsState){
      state.errors = []
    }
  }
})

export const reducer = slice.reducer

export const setErrorText = (value: string): AppThunk => dispatch => {
  dispatch(slice.actions.setErrorText(value))
}

export const getChannelMappings = (channel: Channel, title: string, onFinish: () => void): AppThunk => async dispatch => {
  var data = JSON.stringify({ channel, title })
  try {
    const response = await axios.post<IChannelProfile>(`${process.env.REACT_APP_AUTH_URL}/channel-profiles/get-channel-info`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      }
    })
    dispatch(slice.actions.getChannelMappings(response.data))
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const deleteChannelMappings = (): AppThunk => dispatch => {
  dispatch(slice.actions.deleteChannelMappings())
}

export const getSubscribedChannels = (onFinish: (subscriptions: IServiceSubscription[]) => void): AppThunk => async dispatch => {
  try {
    const response = await axios.get<IServiceSubscription[]>(`${process.env.REACT_APP_AUTH_URL}/channel-profiles/get-subscriptions`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`
      }
    })
    dispatch(slice.actions.getSubscribedChannels(response.data))
    onFinish(response.data)
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const updateDirection = (_id: string, type: string, value: string, onFinish: () => void): AppThunk => async dispatch => {
  var data = JSON.stringify({ _id, type, value })
  try {
    const response = await axios.post<IChannelProfile>(`${process.env.REACT_APP_AUTH_URL}/channel-profiles/update-direction`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      }
    })
    dispatch(slice.actions.getChannelMappings(response.data))
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const getMappingById = (_id: string, parentId: string): AppThunk => async dispatch => {
  var data = JSON.stringify({ parentId, mappingId: _id })
  try {
    const response = await axios.post<IChannelMapping>(`${process.env.REACT_APP_AUTH_URL}/channel-profiles/get-mapping-by-id`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      }
    })
    dispatch(slice.actions.setCurrentMapping(response.data))
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const setCurrentChannel = (channel: Channel): AppThunk => dispatch => {
  dispatch(slice.actions.setCurrentChannel(channel))
}

export const channelsBackToInitialState = (path: string): AppThunk => dispatch => {
  dispatch(slice.actions.channelsBackToInitialState())
  history.push(path)
}

export const getMappingPreview = (parentId: string, mappingId: string, id: string = ''): AppThunk => async dispatch => {
  if (id !== '') {
    var data = JSON.stringify({ parentId, mappingId, id })
  } else {
    var data = JSON.stringify({ parentId, mappingId })
  }
  try {
    const response = await axios.post<object>(`${process.env.REACT_APP_AUTH_URL}/channel-profiles/get-mapping-preview`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      }
    })
    dispatch(slice.actions.getMappingPreview(response.data))
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const saveMapping = (
  parentId: string,
  mappingId: string,
  name: string,
  fieldMappings: IChannelFieldMappings[],
  onFinish: () => void
): AppThunk => async dispatch => {
  var data = JSON.stringify({ parentId, mappingId, name, fieldMappings })
  try {
    await axios.post<IChannelMapping>(`${process.env.REACT_APP_AUTH_URL}/channel-profiles/save-mapping`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      }
    })
    dispatch(slice.actions.saveMapping())
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const setChannelRules = (rules: IChannelRules[]): AppThunk => dispatch => {
  dispatch(slice.actions.setChannelRules(rules))
}

export const getMappingRules = (parentId: string, mappingId: string, onFinish: () => void): AppThunk => async dispatch => {
  var data = JSON.stringify({ parentId, mappingId })
  try {
    const response = await axios.post<any[]>(`${process.env.REACT_APP_AUTH_URL}/channel-profiles/get-mapping-rules`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      }
    })
    const rules = response.data.map(rule => {
      return {
        ...rule, condition: rule.condition.map(condition => {
          if (condition.jsonTree) {
            return {
              ...condition, jsonTree: JSON.parse(condition.jsonTree)
            }
          }
          return condition
        })
      }
    })
    dispatch(slice.actions.setChannelRules(rules))
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const getMappingRule = (parentId: string, mappingId: string, ruleId: string, onFinish: (parsedRule: IChannelRules) => void): AppThunk => async dispatch => {
  var data = JSON.stringify({ parentId, mappingId, ruleId })
  try {
    const response = await axios.post<any>(`${process.env.REACT_APP_AUTH_URL}/channel-profiles/get-mapping-rule`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      }
    })
    let parsedRule = {
      ...response.data, condition: [...response.data.condition.map(condition => {
        if (condition.jsonTree) {
          return {
            ...condition, jsonTree: JSON.parse(condition.jsonTree)
          }
        }
        return condition
      })]
    }
    dispatch(slice.actions.setCurrentRule(parsedRule))
    onFinish(parsedRule)
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const setCurrentRuleToNull = (): AppThunk => dispatch => {
  dispatch(slice.actions.setCurrentRule(null))
}

export const addRule = (rule: IChannelRules, path: string): AppThunk => dispatch => {
  dispatch(slice.actions.addRule(rule))
  history.push(path)
}

export const updateRule = (rule: IChannelRules, ruleIndex: number, path: string): AppThunk => dispatch => {
  dispatch(slice.actions.updateRule({ rule, ruleIndex }))
  history.push(path)
}

export const updateChannelRules = (parentId: string, mappingId: string, rules: IChannelRules[], onFinish: () => void): AppThunk => async dispatch => {
  const stringifiedRules = rules.map(rule => {
    return {
      ...rule, condition: rule.condition.map(condition => {
        if (condition.jsonTree) {
          return {
            ...condition, jsonTree: JSON.stringify(condition.jsonTree)
          }
        }
        return condition
      })
    }
  })
  var data = JSON.stringify({ parentId, mappingId, rules: stringifiedRules })
  try {
    const response = await axios.post<any>(`${process.env.REACT_APP_AUTH_URL}/channel-profiles/update-mapping-rules`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      }
    })
    const rules = response.data.rules.map(rule => {
      return {
        ...rule, condition: rule.condition.map(condition => {
          if (condition.jsonTree) {
            return {
              ...condition, jsonTree: JSON.parse(condition.jsonTree)
            }
          }
          return condition
        })
      }
    })
    dispatch(setChannelRules(rules))
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const setGetRules = (value: boolean): AppThunk => async dispatch => {
  dispatch(slice.actions.setGetRules(value))
}

export const deleteRule = (index: number): AppThunk => async dispatch => {
  dispatch(slice.actions.deleteRule(index))
}

export const setServiceTitle = (value: string, route: string): AppThunk => dispatch => {
  dispatch(slice.actions.setServiceTitle(value))
  history.push(route)
}

export const setServiceTitleWithoutRouting = (value: string): AppThunk => dispatch => {
  dispatch(slice.actions.setServiceTitle(value))
}

export const getShippingMethods = (): AppThunk => async dispatch => {
  try {
    const response = await axios.get<IShippingMethod[]>(`${process.env.REACT_APP_AUTH_URL}/acenda/get-shipping-methods`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`
      }
    })
    dispatch(slice.actions.setShippingMethods(response.data))
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const deleteShippingMethods = (route: string): AppThunk => async dispatch => {
  dispatch(slice.actions.deleteShippingMethods())
  history.push(route)
}

export const resetChannelProfile = (channel: Channel, onFinish: () => void): AppThunk => async dispatch => {
  var data = JSON.stringify({ channel })
  try {
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/channel-profiles/reset-profile`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      }
    })
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const updateNotificationsEmail = (_id: string, notificationsEmail: string, onFinish: () => void): AppThunk => async dispatch => {
  var data = JSON.stringify({ _id, notificationsEmail })
  try {
    const response = await axios.post<IChannelProfile>(`${process.env.REACT_APP_AUTH_URL}/channel-profiles/update-email`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      }
    })
    dispatch(slice.actions.setNotificationsEmail(response.data.notificationsEmail))
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const getActions = (
  storeId: string,
  channelPath: string,
  onFinish: () => void,
  page: number,
  limit: number,
  kernel_command: string = null,
  processing_state: string = null
): AppThunk => async dispatch => {
  try {
    var data = { storeId, channelPath, page, limit }
    if (kernel_command) {
      data["kernel_command"] = kernel_command
    }
    if (processing_state) {
      switch(processing_state){
        case "Processing":
          data["processing_state"] = 2
          break
        case "Success":
          data["processing_state"] = 1
          break
        case "Error":
          data["processing_state"] = 99
          break
      }
    }
    const response = await axios.post<any[]>(`${process.env.REACT_APP_AUTH_URL}/channel-profiles/get-actions`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      }
    })
    dispatch(slice.actions.setActions(response.data))
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const getIntegrationErrors = (
  storeId: string,
  channelPath: string,
  onFinish: () => void,
  page: number,
  limit: number,
  error_type: string = null,
  job_id: number = null,
  acenda_type: string = null,
  acenda_identifier: string = null,
  channel_type: string = null,
  channel_identifier: string = null
):AppThunk => async dispatch => {
  try{
    var data = { storeId, channelPath, page, limit}
    if(error_type){
      data["error_type"] = error_type
    }
    if(job_id){
      data["job_id"] = job_id
    }
    if(acenda_type){
      data["acenda_type"] = acenda_type
    }
    if(acenda_identifier){
      data["acenda_identifier"] = acenda_identifier
    }
    if(channel_type){
      data["channel_type"] = channel_type
    }
    if(channel_identifier){
      data["channel_identifier"] = channel_identifier
    }
    const response = await axios.post<any[]>(`${process.env.REACT_APP_AUTH_URL}/channel-profiles/get-errors`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      }
    })
    dispatch(slice.actions.setErrors(response.data))
    onFinish()
  }catch(error){
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const cleanErrors = ():AppThunk => dispatch => {
  dispatch(slice.actions.cleanErrors())
}

export default slice