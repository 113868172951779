import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFulfillmentRule, ISubscription } from 'src/types/ifulfillmentrule';
import type { AppThunk } from 'src/store'
import { IAcendaAdapter } from 'src/types/imapping';
import axios from 'axios';
import Cookies from 'js-cookie'
import history from 'src/history'
import md5 from 'md5'

interface IFulfillmentState {
  fulfillmentList: IFulfillmentRule[],
  errorText: string,
  acendaAdapters: IAcendaAdapter[]
  shippingMethods: object
  readyState: number
  storesDB: string[]
}

const initialState: IFulfillmentState = {
  fulfillmentList: [],
  errorText: '',
  acendaAdapters: [],
  shippingMethods: null,
  readyState: 0,
  storesDB: []
}

const slice = createSlice({
  name: "fulfillment",
  initialState,
  reducers: {
    setErrorText(state: IFulfillmentState, action: PayloadAction<string>) {
      state.errorText = action.payload
    },
    getAcendaAdapters(state: IFulfillmentState, action: PayloadAction<IAcendaAdapter[]>) {
      state.acendaAdapters = action.payload
    },
    addNewRule(state: IFulfillmentState, action: PayloadAction<IFulfillmentRule>) {
      state.fulfillmentList = [...state.fulfillmentList, action.payload]
    },
    saveFulfillment(){},
    getFulfillmentRules(state: IFulfillmentState, action: PayloadAction<IFulfillmentRule[]>){
      state.fulfillmentList = action.payload
    },
    updateRule(state: IFulfillmentState, action: PayloadAction<{rule: IFulfillmentRule, index: number}>){
      state.fulfillmentList = state.fulfillmentList.map((rule, i) => {
        if(i !== action.payload.index){
          return rule
        }
        return action.payload.rule
      })
    },
    deleteRule(state: IFulfillmentState, action: PayloadAction<number>){
      state.fulfillmentList = state.fulfillmentList.filter((rule, index) => index !== action.payload)
    },
    setFulfillmentList(state: IFulfillmentState, action: PayloadAction<IFulfillmentRule[]>){
      state.fulfillmentList = action.payload
    },
    setShippingMethods(state: IFulfillmentState, action: PayloadAction<object>){
      state.shippingMethods = action.payload
    },
    setReadyState(state: IFulfillmentState, action: PayloadAction<number>){
      state.readyState = action.payload
    },
    setStores(state: IFulfillmentState, action: PayloadAction<string[]>){
      state.storesDB = action.payload
    }
  }
})

export const reducer = slice.reducer

export const getAcendaAdapters = (onGetAcendaAdaptersFinish: () => void): AppThunk => async dispatch => {
  try {
    const response = await axios.post<IAcendaAdapter[]>(`${process.env.REACT_APP_AUTH_URL}/acenda/adapters`, {}, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`
      }
    })
    dispatch(slice.actions.getAcendaAdapters(response.data))
    onGetAcendaAdaptersFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const setErrorText = (value: string): AppThunk => dispatch => {
  dispatch(slice.actions.setErrorText(value))
}

export const addNewRule = (rule: IFulfillmentRule): AppThunk => dispatch => {
  dispatch(slice.actions.addNewRule(rule))
  history.push('/app/acenda/fulfillment')
}

export const saveFulfillment = (fulfillmentList: IFulfillmentRule[], stores: string[], onFinish: () => void): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ fulfillmentList, stores, storeId: Cookies.get('storeIdDataLoader') })
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/subscriptions/save-fulfillment`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      }
    })
    dispatch(slice.actions.saveFulfillment())
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const getFulfillmentRules = (onFinish: (stores: string[], fulfillmentRules: IFulfillmentRule[]) => void):AppThunk => async dispatch => {
  try{
    var data = JSON.stringify({ storeId: Cookies.get('storeIdDataLoader')})
    const response = await axios.post<ISubscription>(`${process.env.REACT_APP_AUTH_URL}/subscriptions/get-fulfillment-rules`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      }
    })
    onFinish(response.data.stores, response.data.fulfillmentRules)
  }catch(error){
    console.log(error)
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const updateRule = (rule: IFulfillmentRule, index: number):AppThunk => async dispatch => {
  dispatch(slice.actions.updateRule({rule, index}))
  history.push('/app/acenda/fulfillment')
}

export const deleteRule = (index: number):AppThunk => dispatch => {
  dispatch(slice.actions.deleteRule(index))
}

export const setFulfillmentList = (rules: IFulfillmentRule[]):AppThunk => dispatch => {
  dispatch(slice.actions.setFulfillmentList(rules))
}

export const getShippingMethods = (stores: string[], onFinish: () => void):AppThunk => async dispatch => {
  try{
      const responseRetailer = await axios.get(`https://admin.acenda.com/preview/${md5(Cookies.get("storeIdDataLoader"))}/api/shippingmethod?access_token=${Cookies.get("acendaAccessToken")}`)
      const sourceShippingMethods = responseRetailer.data.result
      let targetShippingMethods = {}
      const starterPromise = Promise.resolve(null)
      await stores.reduce((p, store) => p.then(async () => {
        const response = await axios.get(`https://admin.acenda.com/preview/${md5(store)}/api/shippingmethod?access_token=${Cookies.get("acendaAccessToken")}`)
        targetShippingMethods[store] = response.data.result
      }), starterPromise)
    dispatch(slice.actions.setShippingMethods({ sourceShippingMethods, targetShippingMethods }))
    onFinish()
  }catch(error){
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const setReadyState = (value: number):AppThunk => dispatch => {
  dispatch(slice.actions.setReadyState(value))
}

export default slice