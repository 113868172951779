import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import axios from 'axios';
import Cookies from 'js-cookie'
import { IQuery } from 'src/types/iquery'
import { IAcendaField, IAcendaProduct } from 'src/types/iacenda'
import history from 'src/history'
import { HelpCircle } from 'react-feather';

interface IQueryState {
  queryList: IQuery[],
  errorText: string,
  acendaFields: IAcendaField[],
  selectedProducts: IAcendaProduct[],
  currentQuery: IQuery
  apiSource: string
}

const initialState: IQueryState = {
  queryList: [],
  errorText: '',
  acendaFields: [],
  selectedProducts: [],
  currentQuery: null,
  apiSource: ''
}

const slice = createSlice({
  name: 'query',
  initialState,
  reducers: {
    setErrorText(state: IQueryState, action: PayloadAction<string>) {
      state.errorText = action.payload
    },
    getQueryList(state: IQueryState, action: PayloadAction<IQuery[]>) {
      state.queryList = action.payload
    },
    deleteQuery(state: IQueryState, action: PayloadAction<string>) {
      state.queryList = state.queryList.filter((query) => query._id !== action.payload)
    },
    getAcendaFields(state: IQueryState, action: PayloadAction<IAcendaField[]>) {
      state.acendaFields = action.payload
    },
    createQuery() { },
    editQuery() { },
    deleteAcendaFields(state: IQueryState) {
      state.acendaFields = []
      state.currentQuery = null
    },
    getSelectedProducts(state: IQueryState, action: PayloadAction<IAcendaProduct[]>) {
      state.selectedProducts = action.payload
    },
    getQueryById(state: IQueryState, action: PayloadAction<IQuery>) {
      state.currentQuery = action.payload
    },
    setApiSource(state: IQueryState, action: PayloadAction<string>) {
      state.apiSource = action.payload
    }
  }
})

export const reducer = slice.reducer

export const getQueryList = (ongetQueryListFinish: () => void): AppThunk => async dispatch => {
  try {
    const response = await axios.post<IQuery[]>(`${process.env.REACT_APP_AUTH_URL}/query/list`, {}, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`
      }
    })
    dispatch(slice.actions.getQueryList(response.data))
    ongetQueryListFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const setErrorText = (value: string): AppThunk => dispatch => {
  dispatch(slice.actions.setErrorText(value))
}

export const deleteQuery = (queryId: string): AppThunk => async dispatch => {
  var data = JSON.stringify({ "_id": queryId })
  try {
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/query/delete`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.deleteQuery(queryId))
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const getAcendaFields = (onFinish: () => void): AppThunk => async dispatch => {
  try {
    const response = await axios.post<IAcendaField[]>(`${process.env.REACT_APP_AUTH_URL}/acenda/get-acenda-fields`, {}, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.getAcendaFields(response.data))
    await sleep(1000)
    onFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const createQuery = (name: string, query: string, queryModel: string, jsonTree: string): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ name, query, jsonTree, queryType: queryModel })
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/query/create`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.createQuery())
    history.push('/app/query')
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const editQuery = (_id: string, name: string, query: string, queryModel: string, jsonTree: string): AppThunk => async dispatch => {
  try {
    var data = JSON.stringify({ _id, name, query, jsonTree, queryType: queryModel })
    await axios.post(`${process.env.REACT_APP_AUTH_URL}/query/edit`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.editQuery())
    history.push('/app/query')
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const deleteAcendaFields = (path: string): AppThunk => dispatch => {
  dispatch(slice.actions.deleteAcendaFields())
  history.push(path)
}

export const setApiSource = (value: string): AppThunk => dispatch => {
  dispatch(slice.actions.setApiSource(value))
}

export const getSelectedProducts = (query: string, storeId: string, queryModel: string, onGetSelectedProductsFinish: () => void): AppThunk => async dispatch => {
  var data = JSON.stringify({ query, apiSource: storeId, queryModel })
  try {
    const response = await axios.post<IAcendaProduct[]>(`${process.env.REACT_APP_AUTH_URL}/acenda/get-acenda-products`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
    })
    dispatch(slice.actions.getSelectedProducts(response.data))
    onGetSelectedProductsFinish()
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const getQueryById = (id: string): AppThunk => async dispatch => {
  try {
    const response = await axios.get<IQuery>(`${process.env.REACT_APP_AUTH_URL}/query/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('accessToken')}`
      }
    })
    dispatch(slice.actions.getQueryById(response.data))
  } catch (error) {
    dispatch(slice.actions.setErrorText(error.response.data.message))
  }
}

export const filterVariantFields = (acendaFields: IAcendaField[], onFinish: () => void): AppThunk => async dispatch => {
  const filtered = acendaFields.filter(field => field.label.startsWith("variant."))
  const resultFields = filtered.map(field => {
    return {...field, label: field.label.substring(8)}
  })
  dispatch(slice.actions.getAcendaFields(resultFields))
  await sleep(1000)
  onFinish()
}

const sleep = (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export default slice